import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import { Device } from '@src/config'
import { useSnack } from '@src/utils'
import { OnboardingStackParamsList } from '@src/navigation/types'
import { tutorialGroupSelector } from './models/tutorials.selectors'
import { TutorialGroups, TutorialSensorKind } from './models/tutorials.types'

export const useTutorialNavigation = (
  tutorialGroup: TutorialGroups,
  tutorialSensorKind?: TutorialSensorKind,
) => {
  const navigation = useNavigation<StackNavigationProp<OnboardingStackParamsList>>()
  const dispatch = useDispatch()
  const snack = useSnack()
  const cmsTutorialGroup = useSelector(tutorialGroupSelector(tutorialGroup))
  const tutorialId =
    tutorialSensorKind &&
    cmsTutorialGroup?.tutorials.find((tutorial) => {
      return tutorial.platform === Device.platform && tutorial.sensorKind === tutorialSensorKind
    })?.id

  useEffect(() => {
    if (cmsTutorialGroup || tutorialGroup === TutorialGroups.NoTutorialGroup) {
      return
    }

    dispatch({
      type: 'tutorials/fetchTutorialGroups',
      payload: { ids: [tutorialGroup] },
      failure: () => {
        snack('Failed to fetch tutorials', 'error')
      },
    })
  }, [cmsTutorialGroup, dispatch, snack, tutorialGroup, tutorialId])

  const navigateToTutorial = useCallback(() => {
    if (tutorialGroup === TutorialGroups.NoTutorialGroup) {
      return
    }

    if (!tutorialId) {
      navigation.navigate('TutorialsGroup', { group: tutorialGroup })
      return
    }

    navigation.navigate('CMSTutorial', { tutorialId })
  }, [navigation, tutorialId, tutorialGroup])

  return navigateToTutorial
}
