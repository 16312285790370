import React, { useEffect, useState } from 'react'
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import {
  EligibilityCheckScreen,
  SelectPlanScreen,
  SelectSubscriptionScreen,
  SubscriptionCheckoutScreen,
  TerraProviderScreen,
} from '@screens'
import {
  StartPurchaseFlowScreen,
  FreeTrialSubscriptionOfferScreen,
  SensorSelection,
} from '@src/screens/SignUp'
import { useReducedMotion } from '@src/config/accessibility'
import { useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { userSelector, uxSensorModeKindSelector } from '@src/selectors/app'
import { TutorialsGroup } from '@src/screens/Tutorials/containers/TutorialsGroup'
import {
  CheckConnectionScreen,
  ConfirmSensorSelectionScreen,
  HealthPermissionScreen,
} from '@src/screens/OwnSensorOnboarding'
import {
  useIsByosLinkupSensorMode,
  useShouldShowByosOnboarding,
  useShouldShowByosOnboardingTutorials,
} from '@src/screens/OwnSensorOnboarding/hooks'
import { ConnectionSuccessfulScreen } from '@src/screens/OwnSensorOnboarding/ConnectionSuccessfulScreen'
import { LibreLinkupIntegration } from '@src/screens/Settings/components/Integrations/LibreLinkupIntegration'
import { LibreAuth } from '@src/screens/Settings/components/Integrations/LibreAuth'
// eslint-disable-next-line max-len
import { IntegrationSuccessfulScreen } from '@src/screens/Settings/components/Integrations/LibreAuth/IntegrationSuccessfulScreen'
import { Feature, useFeatureFlag } from '@src/components'
import { SurveyLinkCollection, SurveysConfigKind } from '@src/types'
import { QuestionnaireNavigator } from '@src/screens/Questionnaire/questionnaire.navigator'
import { FreeTrialSubscriptionOffer } from '@src/screens/SignUp/components/FreeTrialSubscriptionOffer'
import { useByosProducts } from '@src/screens/Marketplace/utils/hooks'
import { useStorageValue } from '@src/utils/storage'
import { Storage } from '@src/utils'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { OnboardingFlowType } from '@src/screens/SignUp/constants'
import { isByosOnboardingSensorMode } from '@src/screens/OwnSensorOnboarding/utils'
import { OnboardingStackParamsList } from '../types'

const OnboardingStack = createStackNavigator<OnboardingStackParamsList>()

export const OnboardingNavigator = () => {
  const reduceMotionEnabled = useReducedMotion()

  const screenOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [productsLoaded, setProductsLoaded] = useState(false)
  const [surveyLinksLoaded, setSurveyLinksLoaded] = useState(false)
  const [ownSensorGoalSurveyFinished, setOwnSensorGoalSurveyFinished] = useState(false)
  const [medicalDisclaimerSurveyFinished, setMedicalDisclaimerSurveyFinished] = useState(false)

  const user = useSelector(userSelector)

  const showPurchaseFlow = useShouldShowPurchaseFlow()
  const shouldShowByosOnboarding = useShouldShowByosOnboarding()
  const shouldShowByosOnboardingTutorials = useShouldShowByosOnboardingTutorials()
  const isByosLinkupSensorMode = useIsByosLinkupSensorMode()
  const byosProducts = useByosProducts()
  const onboardingFlowWithGoalQuestionEnabled = useFeatureFlag(
    Feature.OnboardingFlowWithGoalQuestion,
  )
  const uxSensorModeKind = useSelector(uxSensorModeKindSelector)

  let [flowType] = useStorageValue<OnboardingFlowType>(Storage.ONBOARDING_FLOW_TYPE_SELECTED_KEY)

  if (!flowType && isByosOnboardingSensorMode(uxSensorModeKind)) {
    flowType = OnboardingFlowType.OwnSensor
  }

  const getInitialRouteName = () => {
    if (user && !showPurchaseFlow && shouldShowByosOnboarding) {
      if (onboardingFlowWithGoalQuestionEnabled && !ownSensorGoalSurveyFinished) {
        return 'GoalQuestionnaire' as const
      }

      if (shouldShowByosOnboardingTutorials) {
        return 'ConfirmSensorSelection' as const
      }

      if (isByosLinkupSensorMode) {
        return 'LibreLinkupIntegration' as const
      }

      return 'CheckConnection' as const
    }

    if (!productsLoaded) {
      return 'ProductsLoading' as const
    }

    if (showPurchaseFlow) {
      if (onboardingFlowWithGoalQuestionEnabled && flowType === OnboardingFlowType.OwnSensor) {
        return 'OwnSensorFreeTrialSubscriptionOffer' as const
      }

      if (flowType === OnboardingFlowType.PurchaseStelo && !medicalDisclaimerSurveyFinished) {
        return 'MedicalDisclaimerQuestionnaire' as const
      }

      return 'StartPurchaseFlow' as const
    }

    // check for BYOS
    return 'ConfirmSensorSelection' as const
  }

  const initialRouteName = getInitialRouteName()

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      complete: () => {
        setProductsLoaded(true)
      },
    })

    dispatch({
      type: 'app/surveyQuestionnaire',
      success: ({ surveyLinks }: SurveyLinkCollection) => {
        const ownSensorSurveyLink = surveyLinks.find(
          (link) => link.survey.kind === SurveysConfigKind.OwnSensorGoal,
        )
        const medicalDisclaimerSurveyLink = surveyLinks.find(
          (link) => link.survey.kind === SurveysConfigKind.MedicalDisclaimer,
        )
        if (ownSensorSurveyLink?.finished) {
          setOwnSensorGoalSurveyFinished(true)
        }
        if (medicalDisclaimerSurveyLink?.finished) {
          setMedicalDisclaimerSurveyFinished(true)
        }
      },
      complete: () => {
        setSurveyLinksLoaded(true)
      },
    })
  }, [dispatch, navigation])

  const displayLoading = !productsLoaded || !surveyLinksLoaded

  if (displayLoading) {
    return <LoadingContainer loadingMessage="Loading..." />
  }

  return (
    <OnboardingStack.Navigator initialRouteName={initialRouteName} screenOptions={screenOptions}>
      <OnboardingStack.Screen name="StartPurchaseFlow" component={StartPurchaseFlowScreen} />
      <OnboardingStack.Screen
        name="OwnSensorFreeTrialSubscriptionOffer"
        component={FreeTrialSubscriptionOffer}
        initialParams={{ product: byosProducts[0] }}
      />
      <OnboardingStack.Screen
        name="FreeTrialSubscriptionOffer"
        component={FreeTrialSubscriptionOfferScreen}
      />
      <OnboardingStack.Screen name="SelectPlan" component={SelectPlanScreen} />
      <OnboardingStack.Screen name="SelectSubscription" component={SelectSubscriptionScreen} />
      <OnboardingStack.Screen name="SensorSelection" component={SensorSelection} />
      <OnboardingStack.Screen name="EligibilityCheck" component={EligibilityCheckScreen} />
      <OnboardingStack.Screen name="SubscriptionCheckout" component={SubscriptionCheckoutScreen} />
      <OnboardingStack.Screen
        name="ConfirmSensorSelection"
        component={ConfirmSensorSelectionScreen}
      />
      <OnboardingStack.Screen name="HealthPermission" component={HealthPermissionScreen} />
      <OnboardingStack.Screen name="TerraProvider" component={TerraProviderScreen} />
      <OnboardingStack.Screen name="TutorialsGroup" component={TutorialsGroup} />
      <OnboardingStack.Screen name="CheckConnection" component={CheckConnectionScreen} />
      <OnboardingStack.Screen name="ConnectionSuccessful" component={ConnectionSuccessfulScreen} />
      <OnboardingStack.Screen name="LibreLinkupIntegration" component={LibreLinkupIntegration} />
      <OnboardingStack.Screen name="LibreAuth" component={LibreAuth} />
      <OnboardingStack.Screen
        name="IntegrationSuccessful"
        component={IntegrationSuccessfulScreen}
      />
      <OnboardingStack.Screen
        name="GoalQuestionnaire"
        component={QuestionnaireNavigator}
        initialParams={{
          questionnaire: SurveysConfigKind.OwnSensorGoal,
          nextScreen: { screen: 'ConfirmSensorSelection', params: {} },
        }}
      />
      <OnboardingStack.Screen
        name="MedicalDisclaimerQuestionnaire"
        component={QuestionnaireNavigator}
        initialParams={{
          questionnaire: SurveysConfigKind.MedicalDisclaimer,
          nextScreen: { screen: 'StartPurchaseFlow' },
        }}
      />
    </OnboardingStack.Navigator>
  )
}
