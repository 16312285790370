import React, { useRef } from 'react'
import { View, TouchableOpacity, Keyboard } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Button, Icon, Input, InputRef, Text } from '@components/base'
import { ScrollableAvoidKeyboard } from '@components'
import { Validators } from '@utils'

interface ReferralComponentProps {
  title: string
  description: string
  referralUrl: string
  onChangeText: (text: string) => void
  onBlurText: () => void
  sendInvite: () => void
  email: string
  emailIsTouched: boolean
  sendMessage: (url: string) => void
  copy: (text: string) => void
  share: (url: string) => void
}

export const ReferralComponent = (props: ReferralComponentProps) => {
  const {
    title,
    description,
    referralUrl,
    onChangeText,
    sendInvite,
    email,
    sendMessage,
    copy,
    share,
    onBlurText,
    emailIsTouched,
  } = props
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const inputRef = useRef<InputRef>(null)

  const emailIsInvalid = !Validators.EmailValidator(email)

  const onPress = () => {
    if (emailIsInvalid) {
      inputRef.current?.focus()
      return
    }

    Keyboard.dismiss()

    sendInvite()
  }

  return (
    <ScrollableAvoidKeyboard
      style={styles.scrollView}
      contentContainerStyle={[styles.content, { paddingBottom: (insets.bottom || 0) + 16 }]}
      keyboardShouldPersistTaps="handled"
    >
      <Icon name="gift" style={styles.headerIcon} />
      <Text type="title-3" style={styles.title}>
        {title}
      </Text>
      <Text type="regular" style={styles.subtitle} numberOfLines={3}>
        {description}
      </Text>
      <View style={styles.card}>
        <Input
          ref={inputRef}
          label="Email"
          placeholder="Enter email address"
          keyboardType="email-address"
          value={email}
          autoCapitalize="none"
          autoCorrect={false}
          hasError={emailIsTouched && emailIsInvalid}
          onChangeText={onChangeText}
          onBlur={onBlurText}
          returnKeyType="done"
          onSubmitEditing={onPress}
        />
        <Button
          type="primary"
          size="large"
          disabled={emailIsInvalid}
          style={styles.sendInviteButton}
          onPress={onPress}
          accessibilityLabel="Send invite"
          allowPressWhenDisabled
        >
          Send invite
        </Button>
        <Text type="large" bold style={styles.shareText}>
          Share your link
        </Text>
        {!!referralUrl && (
          <Text type="regular" style={styles.link}>
            {referralUrl}
          </Text>
        )}
        {!!referralUrl && (
          <View style={styles.buttonsContainer}>
            <TouchableOpacity
              accessibilityLabel="Text"
              style={styles.iconButton}
              onPress={() => sendMessage(referralUrl)}
            >
              <Icon name="chat-text" style={styles.buttonIcon} />
              <Text type="regular" style={styles.iconText}>
                Text
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              accessibilityLabel="Copy"
              style={styles.iconButton}
              onPress={() => copy(referralUrl)}
            >
              <Icon name="link" style={styles.buttonIcon} />
              <Text type="regular" style={styles.iconText}>
                Copy
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              accessibilityLabel="More"
              style={styles.iconButton}
              onPress={() => share(referralUrl)}
            >
              <Icon name="dots-three" style={styles.buttonIcon} />
              <Text type="regular" style={styles.iconText}>
                More
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  scrollView: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    alignItems: 'center',
  },
  headerIcon: {
    height: 80,
    marginVertical: 20,
    color: 'theme.primary.base',
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    marginTop: 12,
    marginHorizontal: 16,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  card: {
    marginTop: 24,
    marginHorizontal: 16,
    padding: 24,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.surface.base2',
  },
  sendInviteButton: {
    alignSelf: 'stretch',
    marginTop: 24,
  },
  shareText: {
    textAlign: 'center',
    marginTop: 32,
  },
  link: {
    marginTop: 8,
    textAlign: 'center',
    color: 'theme.text.link',
  },
  buttonsContainer: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconButton: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12,
  },
  iconText: {
    marginTop: 8,
    textAlign: 'center',
  },
  buttonIcon: {
    color: 'theme.text.primary',
    size: 40,
  },
})
