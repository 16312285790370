import moment from 'moment'
import { orderBy } from 'lodash'
import { Model } from '@models'
import { ALL_NOTES } from '@src/screens/NutritionistHub/graphql/allNotest'
import { memberNotesUpdatedAtTimestampSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { Storage } from '@src/utils'
import {
  AppointmentDynamicFilterField,
  AppointmentSortField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  EhrAppointmentCategory,
  FilterAppointmentsKind,
  InsuranceBerryStreetAppointmentStatus,
  NoteCollection,
  Slot,
  SortDirection,
} from '@src/types'
import { CREATE_CHART_NOTE } from '@screens/NutritionistHub/graphql/ehr/createEhrChartNote.ts'
import { RATE_COACH_APPOINTMENT } from '@src/graphql/rateCoachAppointment.ts'
import { FETCH_ALL_AVAILABILITIES } from '../graphql/fetchAllAvailabilities'
import { FETCH_APPOINTMENT_RECURRENCE } from '../graphql/ehr/fetchAppointmentRecurrence'
import { UPSERT_APPOINTMENT_RECURRENCE } from '../graphql/ehr/upsertEhrAppointmentRecurrence'
import { DELETE_APPOINTMENT_RECURRENCE } from '../graphql/ehr/deleteEhrAppointmentRecurrence'
import { FETCH_VIDEO_CALL_INFO } from '../graphql/fetchVideoCallInfo'
import { FETCH_AVAILABLE_DAYS } from '../graphql/fetchAvailableDays'
import { FETCH_AVAILABLE_SLOTS } from '../graphql/fetchAvailableSlots'
import { FETCH_SUGGESTED_AVAILABILITY } from '../graphql/fetchSuggestedAvailability.ts'
import { BOOK_VIDEO_CALL } from '../graphql/bookVideoCall'
import { CANCEL_VIDEO_CALL } from '../graphql/cancelVideoCall'
import { RESCHEDULE_VIDEO_CALL } from '../graphql/rescheduleVideoCall'
import { FETCH_INSURANCE_POLICY } from '../graphql/fetchInsurancePolicy'
import SUBMIT_INSURANCE_POLICY from '../graphql/submitInsurancePolicy'
import { FETCH_COACH_ASSIGNMENTS } from '../graphql/fetchCoachAssignments'
import CLAIM_FREE_VIDEO_CALL from '../graphql/claimFreeVideoCall'
import { ALL_APPOINTMENTS } from '../graphql/allAppointments'
import { FETCH_INSURANCE_CARD_SUBMISSION } from '../graphql/fetchInsuranceCardSubmission.ts'
import SUBMIT_INSURANCE_CARD from '../graphql/submitInsuranceCard.ts'
import { FETCH_EARLIER_AVAILABLE_SLOTS } from '../graphql/fetchEarlierAvailableSlots.ts'
import { FETCH_APPOINTMENT_INFO } from '../graphql/fetchAppointmentInfo.ts'
import { FETCH_EHR_EARLIER_AVAILABLE_SLOTS } from '../graphql/fetchEhrEarlierAvailableSlots.ts'
import { FETCH_EHR_AVAILABLE_DATES } from '../graphql/fetchEhrAvailableDates.ts'
import { FETCH_EHR_AVAILABLE_SLOTS } from '../graphql/fetchEhrAvailableSlots.ts'
import { EHR_APPOINTMENTS } from '../graphql/ehrAppointments.ts'
import { RESCHEDULE_APPOINTMENT } from '../graphql/rescheduleAppointment.ts'
import { CANCEL_APPOINTMENT } from '../graphql/cancelAppointment.ts'
import { FETCH_EHR_SUGGESTED_AVAILABILITY } from '../graphql/fetchEhrSuggestedAvailability.ts'
import CLAIM_FREE_APPOINTMENT from '../graphql/claimFreeAppointment.ts'
import { BOOK_APPOINTMENT } from '../graphql/bookAppointment.ts'
import { FETCH_EHR_ALL_AVAILABILITIES } from '../graphql/fetchEhrAllAvailabilities.ts'
import reducers from './nutritionistHub.reducers'

export default class NutritionistHub {
  namespace = 'nutritionistHub'

  state = {
    ...Model.defaultState,
    latestUserAppointment: null,
    latestUserEhrAppointment: null,
    insurancePolicy: null,
    coachAssignment: null,
    allMemberNotes: [],
    memberNotesUpdatedAt: false,
    upcomingAppointments: [],
    upcomingEhrAppointments: [],
    pastAppointments: [],
    pastEhrAppointments: [],
    pendingAppointments: [],
    pendingEhrAppointments: [],
    suggestedAvailability: null,
    ehrSuggestedAvailability: null,
    earlierAvailableSlotsByAppointmentId: {},
    ehrEarlierAvailableSlotsByAppointmentId: {},
  }

  effects = {
    fetchVideoCallInfo: Model.buildEffect({
      name: `${this.namespace}/fetchVideoCallInfo`,
      query: FETCH_VIDEO_CALL_INFO,
      caching: false,
      dataPath: 'latestUserAppointment',
      warnings: false, // disable warnings as response may be empty
      reducers: [{ name: 'updateLastUserAppointment' }],
    }),
    fetchAppointmentInfo: Model.buildEffect({
      name: `${this.namespace}/fetchAppointmentInfo`,
      query: FETCH_APPOINTMENT_INFO,
      variables: { category: EhrAppointmentCategory.Coaching },
      caching: false,
      dataPath: 'latestUserEhrAppointment',
      warnings: false, // disable warnings as response may be empty
      reducers: [{ name: 'updateLastUserEhrAppointment' }],
    }),
    fetchAvailableDays: Model.buildEffect({
      name: `${this.namespace}/fetchAvailableDays`,
      query: FETCH_AVAILABLE_DAYS,
      caching: false,
      dataPath: 'insuranceSchedulingAvailableDates',
    }),
    fetchEhrAvailableDays: Model.buildEffect({
      name: `${this.namespace}/fetchEhrAvailableDays`,
      query: FETCH_EHR_AVAILABLE_DATES,
      caching: false,
      dataPath: 'ehrAvailableDates',
    }),
    fetchAvailableSlots: Model.buildEffect({
      name: `${this.namespace}/fetchAvailableSlots`,
      query: FETCH_AVAILABLE_SLOTS,
      caching: false,
      dataPath: 'insuranceSchedulingAvailableSlots',
    }),
    fetchEhrAvailableSlots: Model.buildEffect({
      name: `${this.namespace}/fetchEhrAvailableSlots`,
      query: FETCH_EHR_AVAILABLE_SLOTS,
      caching: false,
      dataPath: 'ehrAvailableDateSlots',
    }),
    fetchEarlierAvailableSlots: Model.buildEffect({
      name: `${this.namespace}/fetchEarlierAvailableSlots`,
      query: FETCH_EARLIER_AVAILABLE_SLOTS,
      caching: false,
      dataPath: 'insuranceSchedulingEarlierAvailableSlots',
      warnings: false, // disable warnings as response may be empty
      reducers: [
        {
          name: 'updateEarlierAvailableSlots',
          payload: (
            response: { slots: Slot[] },
            { variables }: { variables: { appointmentId: string } },
          ) => {
            return { appointmentId: variables.appointmentId, slots: response.slots }
          },
        },
      ],
    }),
    fetchEhrEarlierAvailableSlots: Model.buildEffect({
      name: `${this.namespace}/fetchEhrEarlierAvailableSlots`,
      query: FETCH_EHR_EARLIER_AVAILABLE_SLOTS,
      caching: false,
      dataPath: 'ehrEarlierAvailableSlots',
      warnings: false, // disable warnings as response may be empty
      reducers: [
        {
          name: 'updateEhrEarlierAvailableSlots',
          payload: (
            response: { slots: Slot[] },
            { variables }: { variables: { appointmentId: string } },
          ) => {
            return { appointmentId: variables.appointmentId, slots: response.slots }
          },
        },
      ],
    }),
    fetchSuggestedAvailability: Model.buildEffect({
      name: `${this.namespace}/fetchSuggestedAvailability`,
      query: FETCH_SUGGESTED_AVAILABILITY,
      caching: false,
      dataPath: 'schedulingSuggestedAvailability',
      reducers: [{ name: 'updateSuggestedAvailability' }],
    }),
    fetchEhrSuggestedAvailability: Model.buildEffect({
      name: `${this.namespace}/fetchEhrSuggestedAvailability`,
      query: FETCH_EHR_SUGGESTED_AVAILABILITY,
      caching: false,
      dataPath: 'ehrSuggestedAvailability',
      reducers: [{ name: 'updateEhrSuggestedAvailability' }],
    }),
    fetchAllAvailabilities: Model.buildEffect({
      name: `${this.namespace}/fetchAllAvailabilities`,
      query: FETCH_ALL_AVAILABILITIES,
      caching: false,
      dataPath: 'schedulingAllAvailabilities',
    }),
    fetchEhrAllAvailabilities: Model.buildEffect({
      name: `${this.namespace}/fetchEhrAllAvailabilities`,
      query: FETCH_EHR_ALL_AVAILABILITIES,
      caching: false,
      dataPath: 'ehrAllAvailabilities',
    }),
    bookVideoCall: Model.buildEffect({
      name: `${this.namespace}/bookVideoCall`,
      query: BOOK_VIDEO_CALL,
      caching: false,
      dataPath: 'bookVideoCall',
      reducers: [{ name: 'updateLastUserAppointment' }],
    }),
    bookAppointment: Model.buildEffect({
      name: `${this.namespace}/bookAppointment`,
      query: BOOK_APPOINTMENT,
      caching: false,
      dataPath: 'ehrBookAppointment',
      reducers: [{ name: 'updateLastUserEhrAppointment' }],
    }),
    cancelVideoCall: Model.buildEffect({
      name: `${this.namespace}/cancelVideoCall`,
      query: CANCEL_VIDEO_CALL,
      caching: false,
      dataPath: 'cancelVideoCall',
    }),
    cancelAppointment: Model.buildEffect({
      name: `${this.namespace}/cancelAppointment`,
      query: CANCEL_APPOINTMENT,
      caching: false,
      dataPath: 'cancelEhrAppointment',
    }),
    rescheduleVideoCall: Model.buildEffect({
      name: `${this.namespace}/rescheduleVideoCall`,
      query: RESCHEDULE_VIDEO_CALL,
      caching: false,
      dataPath: 'rescheduleVideoCall',
    }),
    rescheduleAppointment: Model.buildEffect({
      name: `${this.namespace}/rescheduleAppointment`,
      query: RESCHEDULE_APPOINTMENT,
      caching: false,
      dataPath: 'rescheduleEhrAppointment',
    }),
    fetchInsurancePolicy: Model.buildEffect({
      name: `${this.namespace}/fetchInsurancePolicy`,
      query: FETCH_INSURANCE_POLICY,
      caching: false,
      warnings: false, // disable warnings as response is empty
      dataPath: 'insurancePolicy',
      reducers: [{ name: 'updateInsurancePolicy' }],
    }),
    submitInsurancePolicy: Model.buildEffect({
      name: `${this.namespace}/submitInsurancePolicy`,
      query: SUBMIT_INSURANCE_POLICY,
      caching: false,
      dataPath: 'submitInsurancePolicy',
      reducers: [{ name: 'updateInsurancePolicy' }],
    }),
    fetchCoachAssignments: Model.buildEffect({
      name: `${this.namespace}/fetchCoachAssignments`,
      query: FETCH_COACH_ASSIGNMENTS,
      caching: false,
      dataPath: 'coachAssignments',
      reducers: [{ name: 'updateTopPriorityCoachAssignment' }],
    }),
    claimFreeVideoCall: Model.buildEffect({
      name: `${this.namespace}/claimFreeVideoCall`,
      query: CLAIM_FREE_VIDEO_CALL,
      caching: false,
      dataPath: 'claimFreeVideoCall',
      reducers: [{ name: 'updateLastUserAppointment' }],
    }),
    claimFreeAppointment: Model.buildEffect({
      name: `${this.namespace}/claimFreeAppointment`,
      query: CLAIM_FREE_APPOINTMENT,
      caching: false,
      dataPath: 'claimFreeEhrAppointment',
      reducers: [{ name: 'updateLastUserEhrAppointment' }],
    }),
    fetchMemberNotes: Model.buildEffect({
      name: `${this.namespace}/fetchMemberNotes`,
      query: ALL_NOTES,
      caching: false,
      dataPath: 'allNotes',
      reducers: [
        { name: 'updatedMemberNotes' },
        {
          name: 'updateMemberNotesUpdatedAtTimestamp',
          *payload(
            response: NoteCollection,
            { select }: any,
          ): Generator<NoteCollection, string, string> {
            const nextUpdatedAtTimestamp = orderBy(response.notes, 'updatedAt', 'desc')[0]
              ?.updatedAt
            const lastUpdatedAtTimestamp = yield select(memberNotesUpdatedAtTimestampSelector)

            if (!lastUpdatedAtTimestamp) {
              return nextUpdatedAtTimestamp || moment().toISOString()
            }

            const hasChanged =
              nextUpdatedAtTimestamp &&
              moment(nextUpdatedAtTimestamp).isAfter(moment(lastUpdatedAtTimestamp))

            if (hasChanged) {
              Storage.set(Storage.NUTRITIONIST_HUB_RED_DOT, true)
              return nextUpdatedAtTimestamp
            }

            return lastUpdatedAtTimestamp
          },
        },
      ],
    }),
    fetchUpcomingAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchUpcomingAppointments`,
      query: ALL_APPOINTMENTS,
      caching: false,
      dataPath: 'allAppointments',
      variables: {
        sorts: [
          {
            direction: SortDirection.Asc,
            field: AppointmentSortField.MeetingAt,
          },
        ],
        dynamicFilters: {
          items: [
            {
              field: AppointmentDynamicFilterField.MeetingStatus,
              operation: DynamicFilterItemOperation.IsNull,
              value: 'true',
            },
            {
              field: AppointmentDynamicFilterField.MeetingAt,
              operation: DynamicFilterItemOperation.Gt,
              value: new Date(),
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
      reducers: [{ name: 'updateUpcomingAppointments' }],
    }),
    fetchUpcomingEhrAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchUpcomingEhrAppointments`,
      query: EHR_APPOINTMENTS,
      caching: false,
      dataPath: 'ehrAppointments',
      variables: {
        filterKind: FilterAppointmentsKind.Upcoming,
        category: EhrAppointmentCategory.Coaching,
        sorts: [
          {
            direction: SortDirection.Asc,
            field: AppointmentSortField.MeetingAt,
          },
        ],
      },
      reducers: [{ name: 'updateUpcomingEhrAppointments' }],
    }),
    fetchPastAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchPastAppointments`,
      query: ALL_APPOINTMENTS,
      caching: false,
      dataPath: 'allAppointments',
      variables: {
        sorts: [
          {
            direction: SortDirection.Desc,
            field: AppointmentSortField.MeetingAt,
          },
        ],
        dynamicFilters: {
          items: [
            {
              field: AppointmentDynamicFilterField.MeetingStatus,
              operation: DynamicFilterItemOperation.NotNull,
              value: 'true',
            },
            {
              field: AppointmentDynamicFilterField.MeetingAt,
              operation: DynamicFilterItemOperation.Lt,
              value: new Date(),
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
      reducers: [{ name: 'updatePastAppointments' }],
    }),
    fetchPastEhrAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchEhrPastAppointments`,
      query: EHR_APPOINTMENTS,
      caching: false,
      dataPath: 'ehrAppointments',
      variables: {
        filterKind: FilterAppointmentsKind.Past,
        category: EhrAppointmentCategory.Coaching,
        sorts: [
          {
            direction: SortDirection.Desc,
            field: AppointmentSortField.MeetingAt,
          },
        ],
      },
      reducers: [{ name: 'updatePastEhrAppointments' }],
    }),
    fetchPendingAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchPendingAppointments`,
      query: ALL_APPOINTMENTS,
      caching: false,
      dataPath: 'allAppointments',
      variables: {
        dynamicFilters: {
          items: [
            {
              field: AppointmentDynamicFilterField.Status,
              operation: DynamicFilterItemOperation.Eq,
              value: InsuranceBerryStreetAppointmentStatus.SchedulingPending,
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
      reducers: [{ name: 'updatePendingAppointments' }],
    }),
    fetchPendingEhrAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchPendingEhrAppointments`,
      query: EHR_APPOINTMENTS,
      caching: false,
      dataPath: 'ehrAppointments',
      variables: {
        filterKind: FilterAppointmentsKind.PendingSchedule,
        category: EhrAppointmentCategory.Coaching,
      },
      reducers: [{ name: 'updatePendingEhrAppointments' }],
    }),
    fetchAppointmentRecurrence: Model.buildEffect({
      name: `${this.namespace}/fetchAppointmentRecurrence`,
      query: FETCH_APPOINTMENT_RECURRENCE,
      caching: false,
      warnings: false, // disable warnings as response is empty
      dataPath: 'ehrAppointmentRecurrence',
      reducers: [{ name: 'updateAppointmentRecurrence' }],
    }),
    upsertAppointmentRecurrence: Model.buildEffect({
      name: `${this.namespace}/upsertAppointmentRecurrence`,
      query: UPSERT_APPOINTMENT_RECURRENCE,
      caching: false,
      dataPath: 'upsertEhrAppointmentRecurrence',
      reducers: [{ name: 'updateAppointmentRecurrence' }],
    }),
    deleteAppointmentRecurrence: Model.buildEffect({
      name: `${this.namespace}/deleteAppointmentRecurrence`,
      query: DELETE_APPOINTMENT_RECURRENCE,
      caching: false,
      dataPath: 'deleteEhrAppointmentRecurrence',
      reducers: [{ name: 'updateAppointmentRecurrence', payload: null }],
    }),
    createChartNote: Model.buildEffect({
      name: `${this.namespace}/createChartNote`,
      query: CREATE_CHART_NOTE,
      caching: false,
      dataPath: 'createEhrChartNote',
    }),
    fetchInsuranceCardSubmission: Model.buildEffect({
      name: `${this.namespace}/fetchInsuranceCardSubmission`,
      query: FETCH_INSURANCE_CARD_SUBMISSION,
      caching: false,
      warnings: false, // disable warnings as response is empty
      dataPath: 'insuranceCardSubmission',
      reducers: [{ name: 'updateInsuranceCardSubmission' }],
    }),
    submitInsuranceCard: Model.buildEffect({
      name: `${this.namespace}/submitInsuranceCard`,
      query: SUBMIT_INSURANCE_CARD,
      caching: false,
      dataPath: 'submitInsuranceCard',
    }),
    rateCoach: Model.buildEffect({
      name: `${this.namespace}/rateCoachAppointment`,
      query: RATE_COACH_APPOINTMENT,
    }),
  }

  reducers = reducers
}
