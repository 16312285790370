import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce, groupBy, uniqueId } from 'lodash'
import { useNavigation } from '@react-navigation/core'
import moment from 'moment'
import { useFocusEffect, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Timeline } from '@src/components/Timeline/Timeline'
import { NavigationBarAccessory } from '@components'
import { eventsForDateSelector, eventsSelector } from '@src/selectors/events'
import { isModified } from '@src/navigation/utils'
import { EventsList } from '@src/screens/Events/components/EventsList'
import { OCCURRED_AT } from '@src/containers/ListFilter/utils'
import { Logger, LoggerScreens, Messages } from '@src/config'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { useChartInteractions } from '@src/screens/Home/utils/useChartInteractions'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { useDispatchAsync } from '@src/utils'
import { calendarDateSelector, dexcomConnectionSelector } from '@src/selectors/app'
import { useHasLargeScreen } from '@src/config/device'
import { IconButton } from '@components/base'
import { PLUS_MENU_BOTTOM_MARGIN } from '@src/screens/Home/containers/Home'
import { AppRouteProp } from '@src/navigation/types'
import { useIsLibreLinkup } from '@src/utils/hooks'
import { eventsChartsSelector } from '@src/models/dailyCharts/selectors'
import { buildSections } from '../utils/utils'
import { EVENTS_DEFAULT_SORT_STATE } from '../models/constants'
import { EventsItemType } from '../models/events.types'

const SEARCH_DELAY = 500

export const EventsContainer = () => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation()
  const hasLargeScreen = useHasLargeScreen()
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)
  const isLibreLinkup = useIsLibreLinkup()

  const route = useRoute<AppRouteProp<'Events'>>()
  const resetFilters = route.params?.resetFilters
  const isDataLoading = useRef(false)
  const dexcomConnection = useSelector(dexcomConnectionSelector)
  const { items, sort, filters } = useSelector(eventsSelector)
  const { startDate, endDate } = useSelector(calendarDateSelector)

  const [query, setQuery] = useState<string>(sort.query || '')
  const [showSearchBar, setShowSearchBar] = useState(false)

  useFocusEffect(
    useCallback(() => {
      if (resetFilters) {
        navigation.setParams({ resetFilters: undefined }) // make sure it runs only once

        const today = moment().format(DATE_FORMAT)
        dispatch({
          type: 'app/changeDateRange',
          payload: {
            startDate: today,
            endDate: today,
          },
        })
        dispatch({
          type: 'events/updateSort',
          payload: {
            ...EVENTS_DEFAULT_SORT_STATE,
          },
        })
      }
    }, [dispatch, navigation, resetFilters]),
  )

  useEffect(() => {
    setQuery(sort.query)
  }, [sort.query])

  const onQueryUpdated = (text: string) => {
    setQuery(text)
    debouncedQueryUpdate(text)
  }

  const onSearchUpdated = useCallback(
    (query: string) => {
      dispatch({
        type: 'events/updateSort',
        payload: {
          ...sort,
          query,
        },
      })

      Logger.sendInfo(LoggerScreens.History, Messages.GeneralSearch, {
        query,
      })
    },
    [dispatch, sort],
  )

  const debouncedQueryUpdate = useMemo(() => {
    return debounce(onSearchUpdated, SEARCH_DELAY)
  }, [onSearchUpdated])

  const refreshData = useCallback(
    async (useCache: boolean) => {
      if (isDataLoading.current) {
        return
      }

      const promises = [
        dispatchAsync({
          type: 'dailyNutrition/fetch',
          useCache,
        }),
        dispatchAsync({
          type: 'dailyCharts/fetch',
          useCache,
        }),
      ]

      isDataLoading.current = true

      try {
        await Promise.all(promises)
      } finally {
        isDataLoading.current = false
      }
    },
    [dispatchAsync],
  )

  const shouldRefreshWithoutCacheOnFocus =
    moment(startDate).isSame(moment().format(DATE_FORMAT)) && (!!dexcomConnection || isLibreLinkup)

  const loadDataOnFocus = useCallback(() => {
    refreshData(!shouldRefreshWithoutCacheOnFocus)
  }, [refreshData, shouldRefreshWithoutCacheOnFocus])

  useFocusEffect(loadDataOnFocus)

  const sections = useMemo(() => buildSections(items), [items])

  const rightAccessories: NavigationBarAccessory[] = [
    {
      iconDescriptor: { pack: 'eva', name: 'search' },
      testID: 'Events/Search',
      iconTintColor: showSearchBar ? 'theme.primary.base' : undefined,
      onPress: () => setShowSearchBar(!showSearchBar),
      accessibilityLabel: 'Search',
    },
    {
      iconDescriptor: { pack: 'eva', name: 'options-2' },
      testID: 'Events/Filter',
      iconTintColor: isModified(sort) ? 'theme.primary.base' : undefined,
      onPress: () => navigation.navigate('EventsEdit'),
      accessibilityLabel: 'Events Settings',
    },
  ]

  const groupedEvents: any[] = useMemo(() => {
    const dateGroups = groupBy(items, (i) => moment(i.occurredAt).format(DATE_FORMAT))
    return Object.entries(dateGroups).map(([date, data], index) => ({
      id: uniqueId(date),
      index,
      title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
      occurredAt: moment(date),
      data,
    }))
  }, [items])

  const {
    listRef,
    primaryChartRef,
    secondaryChartRef,
    highlightedIndex,
    onChartLoadEnd,
    onChartHover,
    onEventListViewableItemsChanged,
    onEventListDragStart,
    onEventListMomentumScrollEnd,
  } = useChartInteractions(groupedEvents)

  return (
    <AccountAvatarNavigationBar rightAccessories={rightAccessories}>
      <EventsList
        ChartComponent={
          <Timeline
            primaryChartRef={primaryChartRef}
            secondaryChartRef={secondaryChartRef}
            onChartHover={onChartHover}
            onChartLoadEnd={onChartLoadEnd}
            hideMultiSlider
            chartsSelector={eventsChartsSelector<EventsItemType>(eventsForDateSelector)}
          />
        }
        listRef={listRef}
        items={sections}
        name="events"
        dataPath="events.events"
        filter={{ scope: 'events' }}
        sort={sort}
        pagination={{ type: sort.orderBy === OCCURRED_AT ? 'date' : 'page' }}
        useCache
        onDataLoadStart={refreshData}
        filters={filters}
        query={query}
        highlightedIndex={highlightedIndex}
        calendar={{ startDate, endDate }}
        onQueryUpdated={onQueryUpdated}
        onViewableItemsChanged={onEventListViewableItemsChanged}
        onDragStart={onEventListDragStart}
        onMomentumScrollEnd={onEventListMomentumScrollEnd}
        showSearchBar={showSearchBar}
      />
      <IconButton
        accessibilityLabel="Open Menu"
        iconName="plus"
        size="l"
        style={[
          styles.toggle,
          { bottom: hasLargeScreen && insets.bottom ? insets.bottom : PLUS_MENU_BOTTOM_MARGIN },
        ]}
        type="primary"
        onPress={() => navigation.navigate('ShowActions')}
      />
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  toggle: {
    position: 'absolute',
    right: 20,
  },
})
