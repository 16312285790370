import React, { TouchableOpacity, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { Icon, Text } from '@components/base'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { ThemeColor } from '@style/theme'

interface CgmProductBoxProps {
  title: string
  selected: boolean
  onPress: () => void
  badge?: 'mostPopular' | 'bestValue'
}

const BADGE_TO_LABEL: Record<NonNullable<CgmProductBoxProps['badge']>, string> = {
  ['mostPopular']: 'Most Popular',
  ['bestValue']: 'Best Value',
}

const BADGE_TO_COLOR: Record<NonNullable<CgmProductBoxProps['badge']>, ThemeColor> = {
  ['mostPopular']: 'theme.accent.citron',
  ['bestValue']: 'theme.accent.tea',
}

export const CgmProductBox = ({ title, selected, onPress, badge }: CgmProductBoxProps) => {
  const styles = useStyleSheet(themedStyle)
  const theme = useTheme()

  const badgeColor = badge ? theme[BADGE_TO_COLOR[badge]] : undefined

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      onPress={onPress}
      style={[
        styles.card,
        { borderColor: selected ? theme['theme.success.base'] : theme['theme.surface.base'] },
        { width: '48%' },
      ]}
    >
      <View style={styles.row}>
        <Text type="large" bold>
          {title.replace('Commitment', '')}
        </Text>

        <View
          style={[
            styles.circle,
            {
              backgroundColor: selected
                ? theme['theme.success.base']
                : theme['theme.surface.base2'],
              borderColor: selected ? theme['theme.success.base'] : theme['theme.surface.base'],
            },
          ]}
        >
          {selected && <Icon name="check" style={styles.icon} />}
        </View>
      </View>

      {badge && (
        <View style={styles.row}>
          <View style={[styles.badge, { backgroundColor: badgeColor }]}>
            <Text type="small" bold style={{ color: theme['theme.text.black'] }}>
              {BADGE_TO_LABEL[badge]}
            </Text>
          </View>
        </View>
      )}
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    backgroundColor: 'theme.surface.base3',
    justifyContent: 'space-between',
    borderRadius: 24,
    borderWidth: 2,
    paddingVertical: 16,
    paddingHorizontal: 18,
    height: 105,
  },
  badge: {
    borderColor: 'theme.transparent',
    borderRadius: 24,
    borderWidth: 2,
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  circle: {
    borderRadius: 50,
    borderWidth: 2,
    height: 32,
    width: 32,
  },
  icon: {
    color: 'theme.solid.white',
  },
  text: {
    color: 'theme.text.primary',
  },
})
