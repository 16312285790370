import arrowRight from './source/arrow-right.png'
import bandage from './source/bandage.png'
import bluetooth from './source/bluetooth.png'
import blurredArcIndicatorsDark from './source/blurred-arc-indicators-dark.jpg'
import blurredArcIndicators from './source/blurred-arc-indicators.jpg'
import blurredDialChartDark from './source/blurred-dial-chart-dark.jpg'
import blurredDialChart from './source/blurred-dial-chart.jpg'
import blurredGlucoseChartDark from './source/blurred-glucose-chart-dark.jpg'
import blurredGlucoseChart from './source/blurred-glucose-chart.jpg'
import boxContent from './source/box-content.png'
import calendarHeart from './source/calendar-heart.png'
import calendar from './source/calendar.png'
import cgmDexcom from './source/cgm-dexcom.png'
import cgmLibre from './source/cgm-libre.png'
import cgm from './source/cgm.jpg'
import coursesModuleDescription from './source/courses-module-description.png'
import dangerSign from './source/danger-sign.png'
import dexcomG6Box from './source/dexcom-g6-box.png'
import dexcomG7Box from './source/dexcom-g7-box.png'
import dexcomSteloBox from './source/dexcom-stelo-box.png'
import glucoseChartMeal from './source/glucose-chart-meal.png'
import glucoseEducation from './source/glucose-education.png'
import glucoseScoreWheelDark from './source/glucose-score-wheel-dark.png'
import glucoseScoreWheel from './source/glucose-score-wheel.png'
import goalsCircles from './source/goals-circles.png'
import googleFitPermissionDark from './source/google-fit-permission-dark.png'
import googleFitPermissionLight from './source/google-fit-permission-light.png'
import healthKitPermissionDark from './source/health-kit-permission-dark.png'
import healthKitPermissionLight from './source/health-kit-permission-light.png'
import hourglass from './source/hourglass.png'
import imageMeal1 from './source/image-meal-1.jpg'
import imageMeditate1 from './source/image-meditate-1.jpg'
import imageReadyToScan1 from './source/image-ready-to-scan.png'
import installCgm from './source/install-cgm.png'
import karaVideo from './source/kara-video.png'
import knowledgeBaseDark from './source/knowledge-base-dark.png'
import knowledgeBaseLight from './source/knowledge-base-light.png'
import libre1 from './source/libre1.png'
import libre2 from './source/libre2.png'
import libre3 from './source/libre3.png'
import libre3Bluetooth from './source/libre3bluetooth.png'
import membershipCardBg from './source/membership-card-bg.png'
import notificationsPermissionDark from './source/notifications-permission-dark.png'
import notificationsPermissionLight from './source/notifications-permission-light.png'
import nutritionist from './source/nutritionist.jpeg'
import nutrisenseSteloConnection from './source/nutrisense-stelo-connection.png'
import cgmSteloBluetooth from './source/cgm-stelo-bluetooth.png'
import runnerFull from './source/runner_full.jpg'
import shower from './source/shower.png'
import upgradeToDexcomSensor from './source/upgrade-to-dexcom-sensor.png'
import videoCall from './source/video-call.jpeg'
import cgmStelo from './source/cgm-stelo.png'
import cgmSteloBright from './source/cgm-stelo-bright.png'
import cgmG6 from './source/cgm-g6.png'
import cgmG7 from './source/cgm-g7.png'
import cgmLibre2 from './source/cgm-libre2.png'
import cgmLibre3 from './source/cgm-libre3.png'
import cgmLibre14day from './source/cgm-libre14day.png'
import scan from './source/scan.png'
import shoppingCartPlus from './source/shopping-cart-plus.png'
import freeTrialOffer from './source/free-trial-offer.png'
import nutritionGuidance from './source/nutrition-guidance.png'
import freeConsultation from './source/free-consultation.png'
import sensorActivatedNutritionGuidance from './source/sensor-activated-nutrition-guidance.png'
import iphoneWApp from './source/iphone-w-app.png'
import marketplaceNN from './source/marketpace-nn.png'
import handWPatches from './source/hand-w-patches.png'
import marketplaceCgm1 from './source/marketplace-cgm1.png'
import marketplaceCgm2 from './source/marketplace-cgm2.png'
import marketplaceCgmPrograms from './source/marketplace-cgm-programs.jpeg'
import marketplaceNutritionistSupport from './source/marketplace-nutritionist-support.png'
import marketplaceInsuranceCovered from './source/marketplace-insurance-covered.png'
import marketplaceRealExperts from './source/marketplace-real-experts.png'
import marketplaceRegularCheckIn from './source/marketplace-regular-checkin.png'
import marketplaceRunner from './source/marketplace-runner.jpg'
import marketplaceNnVideoCalls from './source/marketplace-nn-video-calls.png'

import walkthroughAppDark from './source/walktrough/app-dark.png'
import walkthroughApp from './source/walktrough/app.png'
import walkthroughCommunity from './source/walktrough/community.png'
import walkthroughEducationDark from './source/walktrough/education-dark.png'
import walkthroughEducation from './source/walktrough/education.png'
import walkthroughNutritionists from './source/walktrough/nutritionists.png'

export const imageMeditate1Bg = {
  imageSource: imageMeditate1,
}

export const imageMeal1Bg = {
  imageSource: imageMeal1,
}

export const imageReadyToScan = {
  imageSource: imageReadyToScan1,
}

export const glucoseEducationImage = {
  imageSource: glucoseEducation,
}

export const installCgmImage = {
  imageSource: installCgm,
}

export const boxContentImage = {
  imageSource: boxContent,
}

export const calendarImage = {
  imageSource: calendar,
}

export const showerImage = {
  imageSource: shower,
}

export const dangerSignImage = {
  imageSource: dangerSign,
}

export const hourglassImage = {
  imageSource: hourglass,
}

export const bandageImage = {
  imageSource: bandage,
}

export const knowledgeBaseLightImage = {
  imageSource: knowledgeBaseLight,
}

export const knowledgeBaseDarkImage = {
  imageSource: knowledgeBaseDark,
}

export const coursesModuleDescriptionImage = {
  imageSource: coursesModuleDescription,
}

export const membershipCardBgImage = {
  imageSource: membershipCardBg,
}

export const notificationsPermissionLightImage = {
  imageSource: notificationsPermissionLight,
}

export const notificationsPermissionDarkImage = {
  imageSource: notificationsPermissionDark,
}

export const healthKitPermissionLightImage = {
  imageSource: healthKitPermissionLight,
}

export const healthKitPermissionDarkImage = {
  imageSource: healthKitPermissionDark,
}

export const googleFitPermissionLightImage = {
  imageSource: googleFitPermissionLight,
}

export const googleFitPermissionDarkImage = {
  imageSource: googleFitPermissionDark,
}

export const cgmImage = {
  imageSource: cgm,
}

export const walkthroughAppImage = {
  imageSource: walkthroughApp,
}

export const walkthroughAppDarkImage = {
  imageSource: walkthroughAppDark,
}

export const walkthroughCommunityImage = {
  imageSource: walkthroughCommunity,
}

export const walkthroughEducationImage = {
  imageSource: walkthroughEducation,
}

export const walkthroughEducationDarkImage = {
  imageSource: walkthroughEducationDark,
}

export const walkthroughNutritionistsImage = {
  imageSource: walkthroughNutritionists,
}

export const goalsQuestionnaireGroupImage = {
  imageSource: goalsCircles,
}

export const arrowRightImage = {
  imageSource: arrowRight,
}

export const bluetoothImage = {
  imageSource: bluetooth,
}

export const cgmDexcomImage = {
  imageSource: cgmDexcom,
}

export const cgmLibreImage = {
  imageSource: cgmLibre,
}

export const libre1SensorBoxImage = {
  imageSource: libre1,
}

export const libre2SensorBoxImage = {
  imageSource: libre2,
}

export const libre3SensorBoxImage = {
  imageSource: libre3,
}

export const libre3BluetoothImage = {
  imageSource: libre3Bluetooth,
}

export const cgmSteloImage = {
  imageSource: cgmStelo,
}

export const cgmSteloBrightImage = {
  imageSource: cgmSteloBright,
}

export const cgmG6Image = {
  imageSource: cgmG6,
}

export const cgmG7Image = {
  imageSource: cgmG7,
}

export const cgmLibre2Image = {
  imageSource: cgmLibre2,
}

export const cgmLibre3Image = {
  imageSource: cgmLibre3,
}

export const cgmLibre14dayImage = {
  imageSource: cgmLibre14day,
}

export const scanImage = {
  imageSource: scan,
}

export const nutrisenseSteloConnectionImage = {
  imageSource: nutrisenseSteloConnection,
}

export const cgmSteloBluetoothImage = {
  imageSource: cgmSteloBluetooth,
}

export const shoppingCartPlusImage = {
  imageSource: shoppingCartPlus,
}

export const freeTrialOfferImage = {
  imageSource: freeTrialOffer,
}

export const nutritionGuidanceImage = {
  imageSource: nutritionGuidance,
}

export const freeConsultationImage = {
  imageSource: freeConsultation,
}

export const sensorActivatedNutritionGuidanceImage = {
  imageSource: sensorActivatedNutritionGuidance,
}

export const nutritionistImage = {
  imageSource: nutritionist,
}

export const calendarHeartImage = {
  imageSource: calendarHeart,
}

export const blurredGlucoseChartImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredGlucoseChartDark : blurredGlucoseChart,
})

export const blurredArcIndicatorsImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredArcIndicatorsDark : blurredArcIndicators,
})

export const blurredDialChartImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredDialChartDark : blurredDialChart,
})

export const videoCallImage = {
  imageSource: videoCall,
}

export const dexcomG6BoxImage = {
  imageSource: dexcomG6Box,
}

export const dexcomG7BoxImage = {
  imageSource: dexcomG7Box,
}

export const dexcomSteloBoxImage = {
  imageSource: dexcomSteloBox,
}

export const upgradeToDexcomSensorImage = {
  imageSource: upgradeToDexcomSensor,
}

export const runnerFullImage = {
  imageSource: runnerFull,
}

export const glucoseScoreWheelImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? glucoseScoreWheelDark : glucoseScoreWheel,
})

export const karaVideoImage = {
  imageSource: karaVideo,
}

export const glucoseChartMealImage = {
  imageSource: glucoseChartMeal,
}

export const iphoneWAppImage = {
  imageSource: iphoneWApp,
}

export const marketplaceNNImage = {
  imageSource: marketplaceNN,
}

export const handWPatchesImage = {
  imageSource: handWPatches,
}

export const marketplaceCgm1Image = {
  imageSource: marketplaceCgm1,
}

export const marketplaceCgm2Image = {
  imageSource: marketplaceCgm2,
}

export const marketplaceCgmProgramsImage = {
  imageSource: marketplaceCgmPrograms,
}

export const marketplaceNutritionistSupportImage = {
  imageSource: marketplaceNutritionistSupport,
}

export const marketplaceInsuranceCoveredImage = {
  imageSource: marketplaceInsuranceCovered,
}

export const marketplaceRealExpertsImage = {
  imageSource: marketplaceRealExperts,
}

export const marketplaceRegularCheckInImage = {
  imageSource: marketplaceRegularCheckIn,
}

export const marketplaceRunnerImage = {
  imageSource: marketplaceRunner,
}

export const marketplaceNnVideoCallsImage = {
  imageSource: marketplaceNnVideoCalls,
}
