import React, { useState } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ActivityIndicator, View } from 'react-native'
import { useSelector } from 'react-redux'
import { ImageStyle } from 'react-native-fast-image'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Text } from '@src/components/base/Text'
import { BottomSheet } from '@src/components/BottomSheet'
import { IconButton } from '@components/base'
import { Coach, SurveysConfigKind } from '@src/types'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage, useDispatchAsync } from '@src/utils'
import { AppStackParamList } from '@src/navigation/types'
import { showSnack } from '@src/services/Bluetooth/utils'
import { userSelector } from '@src/selectors/app'

export const RateCoachModal = () => {
  const styles = useStyleSheet(themedStyles)
  const user = useSelector(userSelector)
  const route = useRoute<RouteProp<AppStackParamList, 'RateCoachModal'>>()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const [rating, setRating] = useState(0)

  const nutritionist = user?.lastOccurredCoachingAppointment?.providers[0]?.coach
  const { appointmentId } = route.params

  if (!appointmentId || !nutritionist) {
    return null
  }

  const { fullName, photo } = nutritionist || ({} as Coach)

  const handlePress = async (index: number) => {
    const rating = index + 1
    setRating(rating)

    try {
      await dispatchAsync({
        type: 'nutritionistHub/rateCoach',
        payload: { appointmentId, rating, coachUserId: nutritionist.coachId },
      })
      await dispatchAsync({
        type: 'users/fetch',
      })

      if (rating < 4) {
        navigation.replace('Questionnaire', {
          questionnaire: SurveysConfigKind.PostCallFeedbackLowRating,
        })
      } else {
        navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.PostCallFeedback })
      }
    } catch (e: any) {
      showSnack('Failed to rate the coach. Please try again.', null, 'error')
    }
  }

  const handleOnDismiss = () => {
    Storage.set(`${Storage.COACH_RATING_DISMISSED_KEY}_${appointmentId}`, true)
  }

  return (
    <BottomSheet showDismissButton onDismiss={handleOnDismiss}>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        {photo && (
          <ImageWithPlaceholder
            style={styles.nutritionistPhoto as ImageStyle}
            source={{ uri: photo }}
            placeholderComponent={<ActivityIndicator animating size="small" {...styles.spinner} />}
          />
        )}

        <Text type="title-2" style={styles.title}>
          How satisfied are you with your call with {fullName}?
        </Text>

        <View style={styles.starsContainer}>
          {Array.from(new Array(5)).map((_, index) => {
            return (
              <IconButton
                type="transparent"
                key={index}
                onPress={() => handlePress(index)}
                iconName="star"
                iconProps={{
                  weight: 'fill',
                  style: rating > index ? styles.selected : styles.notSelected,
                }}
                accessibilityLabel="star-rate"
                size="xl"
              />
            )
          })}
        </View>
      </SafeAreaView>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  container: {
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  nutritionistPhoto: {
    width: 80,
    height: 80,
    borderRadius: 48,
    marginVertical: 16,
  },
  spinner: {
    color: 'theme.surface.base',
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
  starsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  notSelected: {
    color: 'theme.surface.base1',
  },
  selected: {
    color: 'theme.marketing.yellow',
  },
})
