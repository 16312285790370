import React, { ReactNode } from 'react'
import { WhiteText } from '@screens/Marketplace/components/NewMarketplace/WhiteText.tsx'
import {
  glucoseChartMealImage,
  glucoseScoreWheelImage,
  marketplaceCgm2Image,
  marketplaceCgmProgramsImage,
  marketplaceInsuranceCoveredImage,
  marketplaceNnVideoCallsImage,
  marketplaceNutritionistSupportImage,
  marketplaceRealExpertsImage,
  marketplaceRegularCheckInImage,
  marketplaceRunnerImage,
} from '@assets/images'
import { BillingProduct } from '@src/types.ts'

interface Definition {
  title: ReactNode
  description: string
  backgroundImage: { imageSource: any }
  backgroundImageColor: string
  benefits: {
    title: string
    description: string
    getImage: (isDarkMode: boolean) => { imageSource: any }
    imagePaddingTop?: number
    imagePaddingBottom?: number
  }[]
}

export const PRODUCT_EXPLANATION_SCREEN_DEFINITION: Record<string, Definition> = {
  ['CgmProducts']: {
    title: (
      <WhiteText type="title-1">
        Your body’s data.{'\n'}Our expert{' '}
        <WhiteText type="title-1" style={{ fontStyle: 'italic' }}>
          insights
        </WhiteText>
        .
      </WhiteText>
    ),
    description:
      "Personalized solutions rooted in science,\nbased on your body's data. Balance your\n" +
      'glucose, boost your metabolism, and shed\nthe weight, for good.',
    backgroundImage: marketplaceCgmProgramsImage,
    backgroundImageColor: '#102F32',
    benefits: [
      {
        title: 'Bluetooth sensors',
        description: 'Monthly shipments of next generation real-time automatic sensors.',
        getImage: (_isDarkMode: boolean) => marketplaceCgm2Image,
        imagePaddingTop: 16,
      },
      {
        title: 'Glucose visualization',
        description: 'Understand how your meals & activities affect your glucose response.',
        getImage: (isDarkMode: boolean) => glucoseScoreWheelImage(isDarkMode),
      },
      {
        title: 'Meal scores',
        description: 'Detailed insights on how a meal\naffected your blood glucose levels.',
        getImage: (_isDarkMode: boolean) => glucoseChartMealImage,
      },
    ],
  },
  ['VideoCalls']: {
    title: (
      <WhiteText type="regular" style={{ fontSize: 29 }}>
        Expert{' '}
        <WhiteText type="regular" style={{ fontStyle: 'italic', fontSize: 29 }}>
          nutritionist support
        </WhiteText>
        ,{'\n'}now covered by insurance.
      </WhiteText>
    ),
    description:
      'Get the right advice that moves you\ntoward your health goals' +
      ' from a registered\ndietitian specialized in your condition.',
    backgroundImage: marketplaceNutritionistSupportImage,
    backgroundImageColor: '#2F3837',
    benefits: [
      {
        title: 'Covered by most insurances',
        description: 'Most members pay $0 out-of-pocket\nfor 3 or more video calls per year.',
        getImage: (_isDarkMode: boolean) => marketplaceInsuranceCoveredImage,
        imagePaddingTop: 20,
        imagePaddingBottom: 20,
      },
      {
        title: 'Real experts, real care and results',
        description:
          'Ditch the generic advice for personalized flexible support from top nutritionists. ',
        getImage: (_isDarkMode: boolean) => marketplaceRealExpertsImage,
        imagePaddingTop: 6,
      },
      {
        title: 'Regular check-ins',
        description: 'Your dietitian helps you expand food choices and build lasting habits.',
        getImage: (_isDarkMode: boolean) => marketplaceRegularCheckInImage,
        imagePaddingTop: 18,
        imagePaddingBottom: 16,
      },
    ],
  },
  ['OwnSensor']: {
    title: (
      <WhiteText type="title-1">
        Your body’s data.{'\n'}Our expert{' '}
        <WhiteText type="title-1" style={{ fontStyle: 'italic' }}>
          insights
        </WhiteText>
        .
      </WhiteText>
    ),
    description:
      "Personalized solutions rooted in science, based\non your body's data. Balance your" +
      'glucose, boost\nyour metabolism, and shed the weight, for good.',
    backgroundImage: marketplaceRunnerImage,
    backgroundImageColor: '#082829B2',
    benefits: [
      {
        title: 'Glucose visualization',
        description: 'Understand how your meals & activities affect your glucose response.',
        getImage: (isDarkMode: boolean) => glucoseScoreWheelImage(isDarkMode),
      },
      {
        title: 'Meal scores',
        description: 'Detailed insights on how a meal\naffected your blood glucose levels.',
        getImage: (_isDarkMode: boolean) => glucoseChartMealImage,
      },
      {
        title: 'Access to Nutritionist video calls',
        description: 'Ability to sign up with 1:1 video calls (insurance-covered).',
        getImage: (_isDarkMode: boolean) => marketplaceNnVideoCallsImage,
        imagePaddingTop: 16,
        imagePaddingBottom: 8,
      },
    ],
  },
}

export const WHAT_YOU_GET = [
  'Access to personalized nutritionist support',
  'Month to month shipments of CGMs',
  'Proprietary meal scores to provide detailed insights on how a meal affected your blood glucose levels',
]

export const CGM_PRODUCT_TO_BADGE: Partial<Record<BillingProduct, 'mostPopular' | 'bestValue'>> = {
  [BillingProduct.NutrisenseMonthly_6]: 'mostPopular',
  [BillingProduct.NutrisenseMonthly_12]: 'bestValue',
}
