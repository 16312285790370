import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { Image, ImageStyle, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { WarningBanner } from '@src/screens/Home/components/Banners/WarningBanner'
import { useIsDarkMode } from '@src/config/theme'
import { cgmSteloBluetoothImage } from '@src/assets/images'
import { AppStackParamList } from '@src/navigation/types'
import { useShowSteloSetupPopup } from '@src/utils/hooks'

export const SteloSetupPopup = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const showPopup = useShowSteloSetupPopup()

  if (!showPopup) {
    return null
  }

  const onPress = () => {
    navigation.navigate('SteloSetup')
  }

  const textColor = isDarkMode ? theme['theme.tooltip.textPrimary'] : theme['theme.text.primary']
  const backgroundColor = isDarkMode
    ? theme['theme.marketing.yellow']
    : theme['theme.marketing.lightYellow']

  const image = (
    <Image source={cgmSteloBluetoothImage.imageSource} style={styles.image as ImageStyle} />
  )

  const body = (
    <View style={styles.textContainer}>
      <Text type="regular" style={[{ color: textColor }]}>
        <Text type="regular" bold style={[{ color: textColor }]}>
          Received your Stelo shipment?
        </Text>{' '}
        {'\n'}
        Tap to start your set up.
      </Text>
    </View>
  )

  return (
    <WarningBanner
      accessibilityLabel="Stelo setup"
      onPress={onPress}
      image={image}
      body={body}
      containerStyle={{ backgroundColor }}
    />
  )
}

const themedStyles = StyleService.create({
  popup: {
    backgroundColor: 'theme.marketing.lightYellow',
  },
  textStyle: {
    color: 'theme.text.secondary',
  },
  image: {
    width: 48,
    height: 48,
    marginRight: 8,
  },
  textContainer: {
    flex: 1,
    marginRight: 8,
  },
})
