import React from 'react'
import { View } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'
import moment from 'moment'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useSelector } from 'react-redux'
import { Intercom } from '@src/utils'
import { Button, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollViewWithFade } from '@src/components'
import { useSlateToReact } from '@src/screens/NutritionistHub/hooks/nutritionistNotes/useSlateToReact'
import { AppStackParamList } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { MemberNoteCreatorInfo } from '@src/screens/NutritionistHub/components/MemberNotes/MemberNoteCreatorInfo'
import {
  noteSelector,
  topPriorityCoachAssignmentSelector,
} from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { coachChatConversationSelector, videoCallProductSelector } from '@src/selectors/app'
import { CoachAssignmentState, CoachAssignmentSupportType } from '@src/types'
import { FULL_DAY_MONTH_AND_DATE_FORMAT } from '@src/screens/NutritionistHub/constants'
import { CustomEventTypes } from '@src/config'
import { useNutritionistHubTracker } from '@src/screens/NutritionistHub/hooks'

export const MemberNoteDetail = () => {
  const {
    params: { noteId },
  } = useRoute<RouteProp<AppStackParamList, 'MemberNoteDetail'>>()

  const note = useSelector(noteSelector(noteId))

  const insets = useSafeAreaInsets()
  const track = useNutritionistHubTracker()
  const { detailedView } = useSlateToReact(note?.body)
  const styles = useStyleSheet(themedStyles)

  const coachChatConversation = useSelector(coachChatConversationSelector)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const videoCallProduct = useSelector(videoCallProductSelector)

  const canAsyncChat =
    CoachAssignmentSupportType.AsyncChat === coachAssignment?.supportType &&
    coachAssignment?.state === CoachAssignmentState.Active

  const canVideoChat =
    CoachAssignmentSupportType.VideoChat === coachAssignment?.supportType &&
    [CoachAssignmentState.Active, CoachAssignmentState.Pending].includes(coachAssignment?.state)

  const canMessageNutritionist = canAsyncChat || canVideoChat

  const handleMessageNutritionistPress = () => {
    track(CustomEventTypes.NutritionistHubNoteDetailMessageNutritionistTapped)
    if (coachChatConversation) {
      Intercom.showIntercomConversation(coachChatConversation.intercomId)
    } else {
      Intercom.showIntercomMessenger({ source: 'NutritionistHub' })
    }
  }

  return (
    <NavigationContainer
      title={moment(note?.pinnedAt || note?.occurredAt).format(FULL_DAY_MONTH_AND_DATE_FORMAT)}
    >
      <ScrollViewWithFade
        style={styles.container}
        scrollViewStyle={[
          styles.contentContainer,
          { paddingBottom: Math.max((insets?.bottom ?? 0) + 16, 40) },
        ]}
      >
        <View style={styles.content}>
          <View>{detailedView}</View>
          {note?.creator && <MemberNoteCreatorInfo creator={note.creator} />}
        </View>
        <View style={styles.footer}>
          <Button
            accessibilityLabel="messageNutritionist"
            type="primary"
            size="block"
            onPress={handleMessageNutritionistPress}
            disabled={!canMessageNutritionist}
          >
            Message Nutritionist
          </Button>
          {!canMessageNutritionist && !!videoCallProduct && (
            <Text type="tiny" style={styles.infoText}>
              Schedule a video call in the Nutritionist tab to activate messaging
            </Text>
          )}
        </View>
      </ScrollViewWithFade>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
  },
  content: {
    flex: 1,
    paddingTop: 26,
    paddingHorizontal: 24,
  },
  nutritionistInfo: {
    paddingTop: 12,
  },
  footer: {
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
  },
  infoText: {
    marginTop: 4,
    alignSelf: 'center',
    color: 'theme.text.tertiary',
  },
})
