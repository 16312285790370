import { ImageBackground, SafeAreaView, TouchableWithoutFeedback, View } from 'react-native'
import React from 'react'
import { useRoute } from '@react-navigation/core'
import { RouteProp, useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { Text } from '@components/base'
import { BenefitCard } from '@screens/Marketplace/components/NewMarketplace/ProductExplanation/BenefitCard.tsx'
import { AppStackParamList } from '@navigation/types'
import { userSelector } from '@selectors/app.ts'
import { SurveysConfigKind } from '@src/types.ts'
import { useIsDarkMode } from '@config/theme.ts'
import { PRODUCT_EXPLANATION_SCREEN_DEFINITION } from '@screens/Marketplace/components/NewMarketplace/definitions.tsx'
import { useNavigateToByosUpgrade } from '@screens/Marketplace/utils/hooks.ts'

const TAP_ANYWHERE_LABEL = 'Tap anywhere to continue'

export const ProductExplanation = () => {
  const styles = useStyleSheet(themedStyle)
  const isDarkMode = useIsDarkMode()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { navigateToByosUpgrade } = useNavigateToByosUpgrade()
  const route = useRoute<RouteProp<AppStackParamList, 'ProductExplanation'>>()
  const { screen } = route.params || {}

  const user = useSelector(userSelector)

  const insuranceSurveyCompleted = user?.surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.Insurance && link.finished,
  )

  const {
    title,
    description,
    backgroundImage,
    backgroundImageColor,
    benefits,
  } = PRODUCT_EXPLANATION_SCREEN_DEFINITION[screen]

  const onPress = () => {
    switch (screen) {
      case 'CgmProducts':
        navigation.navigate('CgmProductSelection')
        break
      case 'OwnSensor':
        navigateToByosUpgrade()
        break
      case 'VideoCalls':
        if (insuranceSurveyCompleted) {
          navigation.replace('Drawer', {
            screen: 'Dashboard',
            params: { screen: 'NutritionistHub' },
          })
        } else {
          navigation.replace('Questionnaire', {
            questionnaire: SurveysConfigKind.Insurance,
            skipIntroScreen: true,
          })
        }
        break
    }
  }

  return (
    <TouchableWithoutFeedback
      style={styles.container}
      accessibilityLabel={TAP_ANYWHERE_LABEL}
      onPress={onPress}
    >
      <View style={styles.imageContainer}>
        <ImageBackground source={backgroundImage.imageSource} style={styles.imageBackground}>
          <View style={[styles.layer, { backgroundColor: backgroundImageColor }]} />

          <SafeAreaView style={styles.content}>
            <View style={styles.description}>
              {title}
              <Text type="regular" style={styles.whiteText}>
                {description}
              </Text>
            </View>

            <View>
              {benefits.map(
                ({ title, description, getImage, imagePaddingTop, imagePaddingBottom }) => {
                  return (
                    <BenefitCard
                      key={title}
                      title={title}
                      description={description}
                      image={getImage(isDarkMode)}
                      imagePaddingTop={imagePaddingTop}
                      imagePaddingBottom={imagePaddingBottom}
                    />
                  )
                },
              )}

              <View style={styles.bottomLabelContainer}>
                <Text type="title-3" style={styles.whiteText}>
                  {TAP_ANYWHERE_LABEL}
                </Text>
              </View>
            </View>
          </SafeAreaView>
        </ImageBackground>
      </View>
    </TouchableWithoutFeedback>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  content: {
    justifyContent: 'space-between',
    marginHorizontal: 16,
    flex: 1,
  },
  description: {
    marginTop: 64,
  },
  imageContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageBackground: {
    flex: 1,
    width: '100%',
  },
  whiteText: {
    color: 'theme.solid.white',
    marginTop: 8,
    fontSize: 15,
  },
  bottomLabelContainer: {
    alignItems: 'center',
    marginTop: 18,
  },
  layer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.7,
  },
})
