import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { IconName, Text } from '@components/base'
import { SectionItem } from '@screens/Profile'
import { Zendesk, openUrl } from '@utils'
import { StyleService, useStyleSheet } from '@src/style/service'
import { clientConfigStoreStateSelector, userSelector } from '@src/selectors/app'
import { Feature, useFeatureFlag } from '@src/components'
import { useIsDexcomResearch } from '@src/utils/hooks'
import { SurveysConfigKind, UiStateNames } from '@src/types'
import { Analytics, CustomEventTypes } from '@src/config'

interface ContactItem {
  icon: IconName
  label: string
  onPress: () => void
}

export const AccountRowsSection = () => {
  const styles = useStyleSheet(themedStyles)
  const user = useSelector(userSelector)
  const navigation = useNavigation()

  const dispatch = useDispatch()
  const config = useSelector(clientConfigStoreStateSelector)

  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)
  const isDexcomResearch = useIsDexcomResearch()

  const shouldShowDexcomResearch =
    isDexcomResearchEnabled &&
    user?.uiStates.some((uiState) => uiState.name === UiStateNames.DexcomSensorPrompt)

  const outcomesTrackingEnabled = useFeatureFlag(Feature.OutcomesTracking)

  const insuranceResubmissionEnabled = useFeatureFlag(Feature.InsuranceResubmission)

  const onResearchConsentPress = () => {
    if (isDexcomResearch) {
      navigation.navigate('Questionnaire', {
        questionnaire: SurveysConfigKind.DexcomResearchConsent,
        allowEarlyExit: true,
        showCompletedScreen: false,
      })
      return
    }

    navigation.navigate('DexcomResearchOptedOutModal')
  }

  const contactItems: ContactItem[] = [
    {
      icon: 'chat-circle-text',
      label: 'Contact us',
      onPress: () => openUrl(Zendesk.baseUrl(user?.email)),
    },
    {
      icon: 'instagram-logo',
      label: 'Follow us on Instagram',
      onPress: () => openUrl('https://www.instagram.com/nutrisenseio/'),
    },
    {
      icon: 'facebook-logo',
      label: 'Join Facebook Group',
      onPress: () => openUrl('https://www.facebook.com/nutrisenseio/'),
    },
    {
      icon: 'trash-simple',
      label: 'Delete my account',
      onPress: () => openUrl(config.privacyCenterUrl),
    },
    {
      icon: 'sign-out',
      label: 'Log out',
      onPress: () => dispatch({ type: 'app/logout' }),
    },
  ]

  return (
    <>
      {outcomesTrackingEnabled && (
        <SectionItem
          hasBottomBorder
          icon="calendar-plus"
          onPress={() => {
            Analytics.track(CustomEventTypes.OutcomesTrackingLabResultsTapped)
            navigation.navigate('LabResultsScreen')
          }}
        >
          <Text type="regular" style={styles.textGrow}>
            Lab Results
          </Text>
        </SectionItem>
      )}
      {insuranceResubmissionEnabled && (
        <SectionItem
          hasBottomBorder
          icon="identification-card"
          onPress={() => {
            navigation.navigate('InsuranceStatus')
          }}
        >
          <Text type="regular" style={styles.textGrow}>
            Insurance Status
          </Text>
        </SectionItem>
      )}
      {shouldShowDexcomResearch && (
        <SectionItem hasBottomBorder icon="pencil-simple-line" onPress={onResearchConsentPress}>
          <View style={styles.textGrow}>
            <Text type="regular">Research Consent</Text>
            <Text type="regular" style={styles.textSecondary}>
              Longitudinal Outcomes of CGM Use
            </Text>
            <Text type="regular" bold style={isDexcomResearch ? styles.optedIn : styles.optedOut}>
              {isDexcomResearch ? 'Opted In' : 'Opted Out'}
            </Text>
          </View>
        </SectionItem>
      )}
      {isDexcomResearchEnabled && isDexcomResearch && (
        <SectionItem
          hasBottomBorder
          icon="files"
          onPress={() => navigation.navigate('ResearchSurveys')}
        >
          <Text type="regular" style={styles.textGrow}>
            Research Surveys
          </Text>
        </SectionItem>
      )}
      {contactItems.map(({ icon, label, onPress }, index) => (
        <SectionItem
          hasBottomBorder={index !== contactItems.length - 1}
          icon={icon}
          key={label}
          onPress={onPress}
        >
          <Text type="regular" style={styles.text}>
            {label}
          </Text>
        </SectionItem>
      ))}
    </>
  )
}

const themedStyles = StyleService.create({
  text: {
    flex: 1,
  },
  textGrow: {
    flex: 1,
  },
  textSecondary: {
    color: 'theme.text.secondary',
  },
  optedIn: {
    marginTop: 4,
    color: 'theme.success.base',
  },
  optedOut: {
    marginTop: 4,
    color: 'theme.disabled.text',
  },
})
