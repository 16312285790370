import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { handleNextAction, PaymentIntent } from '@stripe/stripe-react-native'
import { omit } from 'lodash'
import { StackNavigationProp } from '@react-navigation/stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { useDispatchAsync } from '@src/utils/dispatch'
import { useSnack } from '@src/utils/navigatorContext'
import {
  BillingProduct,
  CheckoutOption,
  Invoice,
  KeyValueString,
  Product,
  ProductGroup,
  PromotionCode,
  Subscription,
  SubscriptionCheckoutOptionKind,
  SurveysConfigKind,
  UiStateNames,
} from '@src/types'
import { productsSelector, subscriptionsSelector, userSelector } from '@src/selectors/app'
import { promoCodeSelector, subscriptionSchedulesSelector } from '@src/selectors/marketplace'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { RootStoreState } from '@src/models/app.types'
import {
  AppStackParamList,
  CommonAuthorizedStackParamList,
  DrawerParamList,
  OnboardingStackParamsList,
} from '@src/navigation/types'
import { Analytics } from '@src/config'
import { UpgradeSubscriptionTrialMutation } from '@src/screens/Marketplace/graphql/upgradeSubscriptionTrial.generated'
import { useAllowedAddonQuantity, useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { EstimateTaxQuery } from '@src/screens/Marketplace/graphql/estimateTax.generated'
import { Feature, useFeatureFlag } from '@src/components'
import { CreateOneTimePaymentMutation } from '@src/screens/Marketplace/graphql/purchaseOneTimePayment.generated'
import { useShouldShowByosOnboardingTutorials } from '@src/screens/OwnSensorOnboarding/hooks'
import {
  GroupableProduct,
  GroupedProducts,
  isProduct,
  ProductSelection,
  PromotionCodeResponse,
} from '../types/types'
import { CreateSubscriptionMutation } from '../graphql/purchaseSubscription.generated'
import { UpgradeSubscriptionProductMutation } from '../graphql/upgradeSubscriptionProduct.generated'
import { getCancelAddonModalTitle } from './utils'

const getEligibleAddons = <T extends Pick<Product, 'core' | 'id'>>(
  products: Array<Pick<Product, 'id'> & { incompatibleAddons: { id: string }[] }>,
  upgradeProducts: Array<Pick<Product, 'id'>>,
  allProducts: T[],
) => {
  const productIds = products.map((product) => product.id)
  const incompatibleAddonIds = products.flatMap((product) =>
    product.incompatibleAddons.map((addon) => addon.id),
  )

  const upgradeProductIds = upgradeProducts.map((product) => product.id)

  return allProducts.filter(
    (product) =>
      !product.core &&
      !incompatibleAddonIds.includes(product.id) &&
      !upgradeProductIds.includes(product.id) &&
      !productIds.includes(product.id),
  )
}

export const useSubscriptionFromRoute = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { params } = useRoute<RouteProp<AppStackParamList, 'ProgramDetails'>>()
  const { subscriptionId } = params || {}

  const subscription = useSelector((state: RootStoreState) =>
    subscriptionsSelector(state).find((subscription) => subscription.id === subscriptionId),
  )

  const primaryProduct = useSelector(productsSelector).find(
    (product) => product.id === subscription?.primaryProduct.id,
  )

  useEffect(() => {
    if (!subscription) {
      navigation.replace('Subscriptions')
    }
  }, [navigation, subscription])

  return { subscription, primaryProduct }
}

export const useActiveSubscriptionAddons = (
  subscription?: Pick<Subscription, 'currentPeriodEndAt' | 'commitmentEndAt'> & {
    primaryProduct: { id: string }
  },
) => {
  const allSubscriptions = useSelector(subscriptionsSelector)
  const allProducts = useSelector(productsSelector)

  if (!subscription) {
    return []
  }

  let currentSubscriptionWillBeCanceledAt = moment(subscription.currentPeriodEndAt)

  if (subscription.commitmentEndAt) {
    const commitmentEndsAt = moment(subscription.commitmentEndAt)
    const commitmentDaysLeft = commitmentEndsAt.diff(moment(), 'days')

    if (commitmentDaysLeft > 0) {
      currentSubscriptionWillBeCanceledAt = commitmentEndsAt
    }
  }

  const allActiveSubscriptions = allSubscriptions.filter(
    (subscription) =>
      subscription.status === 'active' &&
      (!subscription.cancelAt ||
        moment(subscription.cancelAt).isAfter(currentSubscriptionWillBeCanceledAt)),
  )

  // subscription.primaryProduct is a ProductMin without the 'core' property which we need for filtering
  const allActiveSubscriptionProducts = allActiveSubscriptions
    .map((subscription) => subscription.primaryProduct.id)
    .map((id) => allProducts.find((product) => product.id === id))
    .filter((product): product is Product => !!product)

  const activeAddonProductIds = allActiveSubscriptionProducts
    .filter((product) => !product.core && !(subscription.primaryProduct.id === product.id))
    .map((product) => product.id)

  return allActiveSubscriptions
    .map((subscription) => subscription.primaryProduct)
    .filter((product) => activeAddonProductIds.includes(product.id))
}

export const useActivateDiscount = () => {
  const dispatch = useDispatch()
  const isSubmitting = useRef(false)

  return useCallback(
    ({
      code,
      product,
      success,
      failure,
    }: {
      code: string
      product?: string
      success?: ({ promotionCode }: PromotionCodeResponse) => void
      failure?: (error: any) => void
    }) => {
      if (isSubmitting.current) {
        return
      }

      isSubmitting.current = true

      dispatch({
        type: 'marketplace/activateDiscount',
        payload: { code, product },
        success,
        failure,
        complete: () => {
          isSubmitting.current = false
        },
      })
    },
    [dispatch],
  )
}

export const useProducts = () => {
  const activeSubscriptions = useActiveSubscriptions()
  const schedules = useSelector(subscriptionSchedulesSelector)
  const products = useSelector(productsSelector)
  const activeSubscriptionProducts = activeSubscriptions.map(
    (subscription) => subscription.primaryProduct,
  )
  const activeSubscriptionProductIds = activeSubscriptionProducts.map((product) => product.id)

  const activeSubscriptionUpgradeProducts = activeSubscriptions.flatMap(
    (subscription) => subscription.primaryProduct.eligibleUpgradeProducts,
  )

  const scheduledPhases = schedules.flatMap((schedule) => schedule.phases)
  const upcomingSubscriptionPhases = scheduledPhases.filter((phase) => {
    const phaseProduct = phase.primaryProduct

    return (
      moment(phase.startAt).isAfter(moment()) &&
      phaseProduct &&
      !activeSubscriptionProductIds.includes(phaseProduct.id)
    )
  })

  const eligibleAddons = getEligibleAddons(
    activeSubscriptionProducts,
    activeSubscriptionUpgradeProducts,
    products,
  )

  const dietitianAddons = eligibleAddons.filter(
    (product) => product.dietitian || product.dietitianMenu,
  )
  const oneTimeAddons = eligibleAddons.filter((addon) => !addon.dietitian && !addon.dietitianMenu)

  return {
    activeSubscriptions,
    products,
    upcomingSubscriptionPhases,
    dietitianAddons,
    oneTimeAddons,
  }
}

const trackSubscription = ({
  subscriptionTotal,
  subscriptionId,
  onboarding,
  primaryProductId,
  nickname,
  checkoutOptions,
  trial,
}: {
  subscriptionTotal: number
  subscriptionId: string
  onboarding: boolean
  primaryProductId: string
  nickname: string
  checkoutOptions?: CheckoutOption[]
  trial?: boolean
}) => {
  Analytics.trackPurchase({
    revenue: subscriptionTotal,
    nickname,
    onboarding,
    transactionId: subscriptionId,
    productId: primaryProductId,
    checkoutOptionKinds: checkoutOptions?.map((option) => option.kind),
    trial,
  })
}

export const useTaxEstimation = (
  productKey?: BillingProduct,
  promotionCode?: PromotionCode | null,
) => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const user = useSelector(userSelector)

  const [estimatedTax, setEstimatedTax] = useState(0)
  const address = user?.address

  useEffect(() => {
    if (!address || !productKey) {
      setEstimatedTax(0)
      return
    }

    dispatch({
      type: 'marketplace/estimateTax',
      payload: {
        product: productKey,
        providerPromotionCodeId: promotionCode?.providerId ?? null,
      },
      success: ({ estimateTax: { estimatedTax } }: EstimateTaxQuery) => {
        setEstimatedTax(estimatedTax)
      },
      failure: () => {
        showSnack("Couldn't estimate tax!", null, 'error')
        setEstimatedTax(0)
      },
    })
  }, [address, productKey, promotionCode?.providerId, dispatch, showSnack])

  return estimatedTax
}

export const useUpgradeSubscriptionTrial = () => {
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation()
  const user = useSelector(userSelector)
  const showSnack = useSnack()

  const paymentMethod = user?.paymentMethod

  return async (subscriptionId: string, promotionCode?: PromotionCode | null) => {
    if (!subscriptionId || !paymentMethod) {
      return
    }

    const upgradeResponse = await dispatchAsync<UpgradeSubscriptionTrialMutation>({
      type: 'marketplace/upgradeSubscriptionTrial',
      payload: {
        id: subscriptionId,
        providerPaymentMethodId: paymentMethod.stripeId,
        providerPromotionCodeId: promotionCode?.providerId ?? null,
      },
    })
    const { subscription } = upgradeResponse.upgradeSubscriptionTrial

    const onSubscriptionUpgradeSuccess = async () => {
      trackSubscription({
        subscriptionTotal: subscription.price,
        subscriptionId: subscription.id,
        onboarding: false,
        primaryProductId: subscription.primaryProduct.id,
        nickname: subscription.primaryProduct.title,
      })

      await dispatchAsync({ type: 'users/fetch' })

      navigation.navigate('Subscriptions')
    }

    // complimentary subscriptions have a set date for cancelAt
    // thus we can assert the subscription was updated if cancelAt is null
    if (!subscription.cancelAt) {
      await onSubscriptionUpgradeSuccess()
      return
    }

    showSnack("Couldn't complete transaction!", null, 'error')
  }
}

export const useUpgradeSubscriptionProduct = () => {
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const user = useSelector(userSelector)

  const paymentMethod = user?.paymentMethod

  return async (subscriptionId: string, product: Product, promotionCode?: PromotionCode | null) => {
    if (!subscriptionId || !paymentMethod) {
      return
    }

    const upgradeResponse = await dispatchAsync<UpgradeSubscriptionProductMutation>({
      type: 'marketplace/upgradeSubscriptionProduct',
      payload: {
        id: subscriptionId,
        nextProduct: product.key,
        providerPaymentMethodId: paymentMethod.stripeId,
        providerPromotionCodeId: promotionCode?.providerId ?? null,
      },
    })
    const { subscription } = upgradeResponse.upgradeSubscriptionProduct

    const onSubscriptionUpgradeSuccess = async () => {
      trackSubscription({
        subscriptionTotal: subscription.price,
        subscriptionId: subscription.id,
        onboarding: false,
        primaryProductId: subscription.primaryProduct.id,
        trial: !!product.trialDays && product.trialDays > 0,
        nickname: subscription.primaryProduct.title,
      })

      await dispatchAsync({ type: 'users/fetch' })

      navigation.navigate('Subscriptions')
    }

    await onSubscriptionUpgradeSuccess()
  }
}

export const trackOneTimePaymentPurchase = (lineItem: {
  productId: string
  revenue: number
  nickname: string
  onboarding: boolean
  id: string
}) => {
  const { productId, revenue, nickname, onboarding, id } = lineItem
  Analytics.trackPurchase({
    productId,
    revenue,
    nickname,
    onboarding,
    transactionId: id,
  })
}

export const usePurchaseOneTimePayment = () => {
  const dispatchAsync = useDispatchAsync()
  const onboardingIsInProgress = useShouldShowPurchaseFlow()
  const user = useSelector(userSelector)
  const { fullName, email, paymentMethod } = user || {}

  return async (
    selection: ProductSelection,
    promotionCode?: PromotionCode | null,
    metadata?: Array<KeyValueString> | KeyValueString,
  ) => {
    if (!paymentMethod) {
      return
    }

    const {
      createOneTimePayment: { invoice },
    } = await dispatchAsync<CreateOneTimePaymentMutation>({
      type: 'marketplace/purchaseOneTimePayment',
      payload: {
        providerPaymentMethodId: paymentMethod.stripeId,
        providerPromotionCodeId: promotionCode?.providerId ?? null,
        purchasableItems: [
          {
            product: selection.product.key,
            quantity: selection.quantity ?? 1,
          },
        ],
        email,
        fullName,
        metadata,
      },
    })

    const { stripeId, total, primaryProduct } = invoice

    trackOneTimePaymentPurchase({
      onboarding: onboardingIsInProgress,
      productId: primaryProduct.id,
      nickname: primaryProduct.title,
      revenue: total || 0,
      id: stripeId,
    })

    return invoice as Invoice
  }
}

export const usePurchaseSubscription = () => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<
    StackNavigationProp<DrawerParamList & OnboardingStackParamsList>
  >()
  const user = useSelector(userSelector)
  const shouldShowPurchaseFlow = useShouldShowPurchaseFlow()
  const shouldShowOnboardingTutorials = useShouldShowByosOnboardingTutorials()
  const onboardingIsInProgress = shouldShowPurchaseFlow || shouldShowOnboardingTutorials
  const onboardingFlowWithGoalQuestionEnabled = useFeatureFlag(
    Feature.OnboardingFlowWithGoalQuestion,
  )

  const { paymentMethod, email, fullName, address } = user || {}

  return async ({
    productKey,
    checkoutOptionKinds,
    promotionCode,
  }: {
    productKey: string
    checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
    promotionCode?: PromotionCode | null
  }) => {
    const purchaseResponse = await dispatchAsync<CreateSubscriptionMutation>({
      type: 'marketplace/purchaseSubscription',
      payload: {
        providerPaymentMethodId: paymentMethod ? paymentMethod.stripeId : null,
        product: productKey,
        address: omit(address, ['__typename']),
        email,
        fullName,
        providerPromotionCodeId: promotionCode?.providerId ?? null,
        checkoutOptionKinds,
      },
    })

    const {
      createSubscription: {
        subscription: {
          id: subscriptionId,
          price: subscriptionTotal,
          primaryProduct: { id: primaryProductId, title, ownSensor },
          checkoutOptions,
          trialEndAt,
        },
        clientSecret,
      },
    } = purchaseResponse

    const onSubscriptionCreateSuccess = async () => {
      trackSubscription({
        subscriptionTotal,
        subscriptionId,
        onboarding: onboardingIsInProgress,
        primaryProductId,
        nickname: title,
        trial: !!trialEndAt && moment(trialEndAt).isAfter(moment()),
        checkoutOptions,
      })

      await dispatchAsync({ type: 'users/fetch' })

      if (onboardingIsInProgress) {
        dispatch({
          type: 'app/upsertUiState',
          payload: {
            name: UiStateNames.CheckoutCompleted,
            value: true,
          },
        })
        if (ownSensor) {
          if (onboardingFlowWithGoalQuestionEnabled) {
            navigation.navigate('GoalQuestionnaire', {
              questionnaire: SurveysConfigKind.OwnSensorGoal,
              nextScreen: { screen: 'ConfirmSensorSelection', params: {} },
            })
          } else {
            navigation.navigate('ConfirmSensorSelection')
          }
        }
        return
      }

      navigation.replace('Subscriptions')
    }

    if (!clientSecret) {
      await onSubscriptionCreateSuccess()
      return
    }

    const { error: cardActionError, paymentIntent } = await handleNextAction(clientSecret)

    if (paymentIntent?.status === PaymentIntent.Status.Succeeded) {
      await onSubscriptionCreateSuccess()
      return
    }

    if (cardActionError) {
      throw new Error("Couldn't complete transaction!")
    }
  }
}

export const usePromotionCode = (product?: BillingProduct) => {
  const promotionCode = useSelector(promoCodeSelector)
  const activateDiscount = useActivateDiscount()

  useEffect(() => {
    // Makes sure promotion code is applicable to current product
    if (promotionCode?.code && product) {
      activateDiscount({
        code: promotionCode.code,
        product,
      })
    }
  }, [promotionCode?.code, product, activateDiscount])

  return promotionCode
}

export const useCalculateSavings = (item: GroupedProducts) =>
  useCallback(
    (product: Product) => {
      const basePlanAmount = item.products.find((product) => !product.commitmentMonths)?.price ?? 0
      return basePlanAmount > product.price ? (basePlanAmount - product.price) * 12 : 0
    },
    [item],
  )

const byOrder = (a: GroupableProduct, b: GroupableProduct) => a.order - b.order

export const useGroupedProducts = (products: Product[]): Array<GroupableProduct> =>
  useMemo(() => {
    const groups = Object.values(
      products.reduce((acc: Record<string, ProductGroup>, product) => {
        if (product.productGroup) {
          acc[product.productGroup.id] = product.productGroup
        }
        return acc
      }, {}),
    )

    const groupableProducts = groups.map(
      (group) =>
        ({
          ...group,
          products: products
            .filter((product) => product.productGroup?.id === group.id)
            .sort(byOrder),
        } as GroupableProduct),
    )

    const productsWithoutGroups = products.filter((product) => !product.productGroup?.id)

    return groupableProducts
      .concat(productsWithoutGroups)
      .filter((item) => isProduct(item) || item.products.length > 0)
      .sort(byOrder)
  }, [products])

export const useInsuranceCoverage = () => {
  const insuranceCoveredVideoConsultFeature = useFeatureFlag(Feature.InsuranceCoveredVideoConsult)

  const user = useSelector(userSelector)

  const userEligibleForInsuranceCoverage =
    user && 'eligibleForInsuranceCoverage' in user ? user.eligibleForInsuranceCoverage : false

  return useCallback(
    (item: Product) => {
      return (
        insuranceCoveredVideoConsultFeature &&
        item.insuranceCoverage &&
        userEligibleForInsuranceCoverage
      )
    },
    [insuranceCoveredVideoConsultFeature, userEligibleForInsuranceCoverage],
  )
}

export const useSelectedAddonModal = () => {
  const navigation = useNavigation<
    DrawerNavigationProp<DrawerParamList> &
      StackNavigationProp<CommonAuthorizedStackParamList & AppStackParamList>
  >()
  const { getAllowedAddonQuantity } = useAllowedAddonQuantity()

  return (product: Product) => {
    const { allowedAddonQuantity, addonLimit } = getAllowedAddonQuantity(product.id)

    if (allowedAddonQuantity === 0) {
      navigation.navigate('CancelModal', {
        title: getCancelAddonModalTitle(addonLimit ?? 0),
        cancelText: 'Contact Support',
        confirmText: 'Nevermind',
        parentScreen: 'Marketplace',
      })
      return
    }

    navigation.navigate('ProductsSelection', {
      selectedAddon: product,
      addons: [product],
    })
  }
}

export const useByosProducts = () => {
  const dispatch = useDispatch()
  const products = useSelector(productsSelector)
  const byosProducts = products.filter((product) => product.ownSensor)

  useEffect(() => {
    dispatch({ type: 'app/fetchProducts' })
  }, [dispatch])

  return byosProducts
}

export const useSubscriptionEligibleForByosUpgrade = () => {
  const subscriptions = useActiveSubscriptions()

  return subscriptions.find((subscription) =>
    subscription.primaryProduct.eligibleUpgradeProducts.some((product) => product.ownSensor),
  )
}

export const useCanUpgradeToProduct = (
  subscription: Subscription | undefined,
  productId: string | undefined,
) => {
  if (!subscription || !productId) {
    return false
  }
  const eligibleUpgradeProducts = subscription.primaryProduct.eligibleUpgradeProducts
  return eligibleUpgradeProducts.some((product) => product.id === productId)
}

export const useNavigateToByosUpgrade = () => {
  const byosProducts = useByosProducts()
  const subscriptionEligibleForByosUpgrade = useSubscriptionEligibleForByosUpgrade()
  const navigation = useNavigation()

  const navigateToByosUpgrade = () => {
    navigation.navigate('EligibilityCheck', {
      product: byosProducts[0],
      nextScreen: {
        screen: 'Questionnaire',
        params: {
          questionnaire: SurveysConfigKind.OwnSensor,
          nextScreen: {
            screen: 'FreeTrialSubscriptionOffer',
            params: {
              product: byosProducts[0],
              subscription: subscriptionEligibleForByosUpgrade,
            },
          },
        },
      },
    })
  }

  return {
    byosProducts,
    subscriptionEligibleForByosUpgrade,
    navigateToByosUpgrade,
  }
}
