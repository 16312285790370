import React, { useEffect, useState } from 'react'
import { Image, ImageStyle, View, TouchableOpacity, TextStyle } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StackNavigationProp } from '@react-navigation/stack'
import { Moment } from 'moment'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { InlineAlert, ProgressBar, ScrollViewWithFade, VideoPlayer } from '@components'
import { openUrl, Storage, useDispatchAsync } from '@src/utils'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppStackParamList, OnboardingStackParamsList } from '@src/navigation/types'
import {
  useIsByosLinkupSensorMode,
  useIsByosScannableSensorMode,
  useShouldShowByosOnboarding,
} from '@src/screens/OwnSensorOnboarding/hooks'
import { UiStateNames } from '@src/types'
import { useStorageValue } from '@src/utils/storage'
import { userSelector } from '@src/selectors/app'
import { Tutorial } from '../models/tutorials.types'
import {
  isPlatformLink,
  isThirdPartyIntegrationTutorial,
  isTutorialLink,
  isTutorialVideo,
  platformLinkUrl,
  shouldNavigateToNextTutorial,
} from './utils'

const Link = ({ text, url, style }: { text: string; url: string; style: TextStyle }) => (
  <TouchableOpacity accessibilityLabel={text} onPress={() => openUrl(url)}>
    <Text type="regular" style={style}>
      {text}
    </Text>
  </TouchableOpacity>
)

export const CarouselTutorial = ({ tutorial }: { tutorial: Tutorial }) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const { tutorialSteps, id: tutorialId } = tutorial

  const byosOnboardingTutorial = useShouldShowByosOnboarding()
  const isByosScannableSensorMode = useIsByosScannableSensorMode()
  const isByosLinkupSensorMode = useIsByosLinkupSensorMode()
  const user = useSelector(userSelector)
  const dispatchAsync = useDispatchAsync()
  const [steloTutorialFinishedAt, setSteloTutorialFinishedAt] = useStorageValue<Moment>(
    `${Storage.STELO_TUTORIAL_FINISHED_AT_KEY}_${user?.id}`,
  )

  const customLastStepButton = byosOnboardingTutorial || isThirdPartyIntegrationTutorial(tutorial)

  useEffect(() => {
    setCurrentStepIndex(0)
  }, [setCurrentStepIndex, tutorialId])

  if (tutorialSteps.length === 0) {
    return null
  }

  const currentStep = tutorialSteps[currentStepIndex]
  const isLastStep = currentStepIndex === tutorialSteps.length - 1

  const videoAction = currentStep.action.find(isTutorialVideo)
  const linkAction = currentStep.action.find(isTutorialLink)
  const platformLink = currentStep.action.find(isPlatformLink)
  const buttonUrl = platformLink ? platformLinkUrl(platformLink) : linkAction?.url

  const onBackPress = () => {
    if (currentStepIndex === 0) {
      navigation.goBack()
    } else {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }

  const onNextPress = () => {
    setCurrentStepIndex(currentStepIndex + 1)
  }

  const byosTutorialCompletionSideEffects = async () => {
    if (isByosScannableSensorMode || isByosLinkupSensorMode) {
      await dispatchAsync({
        type: 'app/upsertUiState',
        payload: {
          name: UiStateNames.OwnSensorTutorialCompleted, // we mark onboarding completion in 'check connection'
          value: true,
        },
      })
    }

    if (isByosScannableSensorMode) {
      await dispatchAsync({
        type: 'app/upsertUiState',
        payload: {
          name: UiStateNames.OwnSensorOnboardingCompleted,
          value: true,
        },
      })
    }
  }

  const tutorialCompletionSideEffects = () => {
    if (isThirdPartyIntegrationTutorial(tutorial) && !steloTutorialFinishedAt) {
      setSteloTutorialFinishedAt(moment())
    }
  }

  const postTutorialNavigation = () => {
    // links from the CMS take precedence
    if (platformLink && shouldNavigateToNextTutorial(platformLink)) {
      openUrl(platformLinkUrl(platformLink))
      return
    }

    if (buttonUrl) {
      openUrl(buttonUrl)
      return
    }

    if (isByosScannableSensorMode) {
      return navigation.replace('AddScan', {
        action: 'activate',
        parentScreen: 'Drawer',
        parentScreenParams: { screen: 'Dashboard' },
      })
    }

    if (isByosLinkupSensorMode) {
      return navigation.navigate('LibreLinkupIntegration')
    }

    if (isThirdPartyIntegrationTutorial(tutorial)) {
      return navigation.navigate('CheckConnection')
    }

    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  const handleLastStepPress = async () => {
    await byosTutorialCompletionSideEffects()
    tutorialCompletionSideEffects()
    postTutorialNavigation()
  }

  return (
    <NavigationContainer title={currentStep.title}>
      <View style={[styles.container, { paddingBottom: insets.bottom + 16 }]}>
        <ProgressBar
          style={styles.progressBar}
          currentStep={currentStepIndex}
          stepsCount={tutorialSteps.length}
          duration={900}
        />
        <ScrollViewWithFade scrollViewStyle={styles.scrollView}>
          {currentStep.image?.data && !videoAction && (
            <Image
              source={{ uri: currentStep.image.data.attributes.url }}
              style={styles.media as ImageStyle}
            />
          )}
          {videoAction && (
            <VideoPlayer
              source={{ uri: videoAction.video?.data.attributes.url }}
              style={styles.media}
              showControls={false}
              isLooping
              isMuted
              playOnLoad
            />
          )}
          {currentStep.description && (
            <Text type="regular" style={styles.description}>
              {currentStep.description}
            </Text>
          )}
          {currentStep.warning && (
            <InlineAlert style={styles.warningMessage} message={currentStep.warning} />
          )}
          {linkAction && !isLastStep && !platformLink && (
            <Link text={currentStep.buttonText} url={linkAction.url} style={styles.linkAction} />
          )}

          {platformLink && !isLastStep && (
            <Link
              text={currentStep.buttonText}
              url={platformLinkUrl(platformLink)}
              style={styles.linkAction}
            />
          )}
        </ScrollViewWithFade>
        <View style={styles.buttonsContainer}>
          <Button
            type="outline"
            size="block"
            style={styles.backButton}
            onPress={onBackPress}
            accessibilityLabel="Back"
          >
            Back
          </Button>
          {!isLastStep ? (
            <Button
              type="primary"
              size="block"
              style={styles.nextButton}
              onPress={onNextPress}
              accessibilityLabel="Next"
            >
              Next
            </Button>
          ) : (
            (linkAction || platformLink || customLastStepButton) && (
              <Button
                type="primary"
                size="block"
                style={styles.nextButton}
                onPress={handleLastStepPress}
                accessibilityLabel={currentStep.buttonText || 'Next'}
              >
                {currentStep.buttonText}
              </Button>
            )
          )}
        </View>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  progressBar: {
    margin: 16,
  },
  scrollView: {
    padding: 16,
  },
  media: {
    width: '100%',
    maxWidth: 600,
    maxHeight: 600,
    alignSelf: 'center',
    height: undefined,
    aspectRatio: 1,
    borderRadius: 16,
  },
  description: {
    marginTop: 24,
    marginBottom: 16,
  },
  warningMessage: {
    marginVertical: 16,
  },
  linkAction: {
    marginBottom: 16,
    color: 'theme.text.link',
    textDecorationLine: 'underline',
  },
  buttonsContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  backButton: {
    flex: 1,
    marginRight: 8,
  },
  nextButton: {
    flex: 2,
  },
})
