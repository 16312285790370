import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { Divider } from '@ui-kitten/components'
import { NavigationContainer } from '@screens/Common/containers'
import { StyleService, useStyleSheet } from '@style/service.ts'
import { useDispatchAsync } from '@utils'
import { CommonRefreshControl } from '@components'
import { BillingProduct, UiPillType } from '@src/types.ts'
import { userSelector } from '@selectors/app.ts'
import { useProducts, useSelectedAddonModal } from '@screens/Marketplace/utils/hooks.ts'
import { PurchasedProductSummary } from '@screens/Marketplace/components/NewMarketplace/PurchasedProductSummary.tsx'
import { ProductSummary } from '@screens/Marketplace/components/NewMarketplace/ProductSummary.tsx'
import { Text } from '@components/base'
import {
  handWPatchesImage,
  iphoneWAppImage,
  marketplaceCgm1Image,
  marketplaceCgm2Image,
  marketplaceNNImage,
} from '@assets/images'
import { getSubscriptionTagDescriptor } from '@screens/Marketplace/utils/utils.ts'

export const CGM_PRODUCTS = [
  BillingProduct.NutrisenseMonthly,
  BillingProduct.NutrisenseMonthly_3,
  BillingProduct.NutrisenseMonthly_6,
  BillingProduct.NutrisenseMonthly_12,
]

export const NewMarketplace = () => {
  const user = useSelector(userSelector)
  const scrollViewRef = useRef<ScrollView>(null)
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation()
  const onAddonSelected = useSelectedAddonModal()

  const [refreshing, setRefreshing] = useState(false)

  const refreshingRef = useRef(refreshing)

  const { activeSubscriptions, oneTimeAddons, upcomingSubscriptionPhases } = useProducts()

  const coreSubscription = activeSubscriptions.find(
    (sub) => sub.id === user?.lastCoreSubscription?.id,
  )
  const secondarySubscriptions = user?.lastCoreSubscription
    ? activeSubscriptions.filter((s) => !s.primaryProduct.core)
    : []

  const eligibleUpgradeProducts = coreSubscription?.primaryProduct?.eligibleUpgradeProducts || []

  const cgmProducts =
    eligibleUpgradeProducts.filter((product) =>
      CGM_PRODUCTS.includes(product.key as BillingProduct),
    ) || []

  const showCgmProducts = cgmProducts.length > 0
  const showByosProducts = eligibleUpgradeProducts.some((product) => product.ownSensor)

  const cgmAddon = oneTimeAddons.find((product) => product.key === BillingProduct.DexcomCgm)
  const patchesAddon = oneTimeAddons.find(
    (product) => product.key === BillingProduct.PaidDexcomPatch,
  )
  const showAddons = [cgmAddon, patchesAddon].some((addon) => addon)

  const onVideoCallsPress = () => {
    navigation.navigate('ProductExplanation', { screen: 'VideoCalls' })
  }

  const onCgmProgramsPress = () => {
    navigation.navigate('ProductExplanation', { screen: 'CgmProducts' })
  }

  const onByosPress = () => {
    navigation.navigate('ProductExplanation', { screen: 'OwnSensor' })
  }

  const onRefresh = useCallback(async () => {
    refreshingRef.current = true
    setRefreshing(true)

    try {
      await Promise.all([
        dispatchAsync({ type: 'users/fetch' }),
        dispatchAsync({ type: 'app/fetchProducts' }),
        dispatchAsync({ type: 'marketplace/fetchSubscriptionSchedules' }),
      ])
    } finally {
      refreshingRef.current = false
      setRefreshing(false)
    }
  }, [dispatchAsync])

  useEffect(() => {
    onRefresh()
  }, [onRefresh])

  useFocusEffect(
    useCallback(() => {
      if (refreshingRef.current) {
        return
      }

      dispatchAsync({ type: 'users/fetch' })
    }, [dispatchAsync]),
  )

  return (
    <NavigationContainer title="Programs & Add-ons">
      <ScrollView
        ref={scrollViewRef}
        style={styles.container}
        contentContainerStyle={{ paddingBottom: (insets?.bottom || 0) + 16 }}
        refreshControl={<CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        {coreSubscription && (
          <PurchasedProductSummary
            subscription={coreSubscription}
            product={coreSubscription.primaryProduct}
            {...getSubscriptionTagDescriptor(coreSubscription)}
          />
        )}

        {secondarySubscriptions.map((subscription) => (
          <PurchasedProductSummary
            key={`subscription-${subscription.id}`}
            subscription={subscription}
            product={subscription.primaryProduct}
            {...getSubscriptionTagDescriptor(subscription)}
          />
        ))}

        {upcomingSubscriptionPhases.map((phase) => (
          <PurchasedProductSummary
            key={`phase-${phase.id}`}
            product={phase.primaryProduct!}
            pillLabel="Scheduled to start"
            pillStatus={UiPillType.Success}
          />
        ))}

        <Divider style={styles.divider} />

        <View style={{ margin: 16 }}>
          <Text type="title-3">Programs</Text>

          {showCgmProducts && (
            <ProductSummary
              title="CGM Programs"
              description="Optimize your health with glucose monitoring and dietitian support."
              image={iphoneWAppImage}
              onPress={onCgmProgramsPress}
            />
          )}
          <ProductSummary
            title="Nutritionist Video Calls"
            description="In-network, in-the-moment video consultations with expert nutritionists."
            image={marketplaceNNImage}
            onPress={onVideoCallsPress}
          />
          {showByosProducts && (
            <ProductSummary
              title="Bringing My Own CGM"
              description="Activate your own sensor seamlessly with the Nutrisense app."
              image={marketplaceCgm2Image}
              onPress={onByosPress}
            />
          )}
        </View>

        {showAddons && (
          <>
            <Divider style={styles.divider} />

            <View style={{ margin: 16 }}>
              <Text type="title-3">Add-ons</Text>

              {cgmAddon && (
                <ProductSummary
                  title="A-la-carté CGMs"
                  description={cgmAddon.description || ''}
                  image={marketplaceCgm1Image}
                  onPress={() => onAddonSelected(cgmAddon)}
                />
              )}
              {patchesAddon && (
                <ProductSummary
                  title="Protective Patches"
                  description={patchesAddon.description || ''}
                  image={handWPatchesImage}
                  imageResizeMode="contain"
                  onPress={() => onAddonSelected(patchesAddon)}
                />
              )}
            </View>
          </>
        )}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  divider: {
    backgroundColor: 'theme.surface.base2',
    height: 8,
  },
})
