import React, { useEffect, useState } from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet, InlineAlert } from '@src/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { Storage } from '@src/utils'
import { sensorActivatedNutritionGuidanceImage } from '@src/assets/images'
import {
  SurveyLinkCollection,
  SurveysConfigKind,
  SurveysQuestionKey,
  UiInlineAlert,
} from '@src/types'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { userSelector } from '@src/selectors/app'
import { AppStackParamList } from '@src/navigation/types'
import { humanizeOwnSensorGoalSurveyAnswer } from '../utils/utils'

export const OwnSensorActivatedNutritionistUpsellModal = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const [goalSurveyLinkLoaded, setGoalSurveyLinkLoaded] = useState(false)
  const [goalSurveyAnswer, setGoalSurveyAnswer] = useState<string | undefined>(undefined)
  const user = useSelector(userSelector)

  const onDismiss = () => {
    Storage.set(
      `${Storage.OWN_SENSOR_ACTIVATED_VIDEO_CALL_UPSELL_MODAL_COMPLETED_KEY}_${user?.id}`,
      true,
    )
  }

  const onSeeIfYouQualifyPress = () => {
    Storage.set(
      `${Storage.OWN_SENSOR_ACTIVATED_VIDEO_CALL_UPSELL_MODAL_COMPLETED_KEY}_${user?.id}`,
      true,
    )
    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
  }

  useEffect(() => {
    dispatch({
      type: 'app/surveyQuestionnaire',
      success: ({ surveyLinks }: SurveyLinkCollection) => {
        const surveyLink = surveyLinks.find(
          (link) => link.survey.kind === SurveysConfigKind.OwnSensorGoal,
        )

        if (surveyLink?.finished) {
          const surveyResponse = surveyLink?.responses.find(
            (response) => response.questionKey === SurveysQuestionKey.OwnSensorGoal,
          )

          const surveyResponseValue = surveyResponse?.answer['value']
          if (surveyResponseValue) {
            const ownSensorGoalIsFreeText = surveyResponseValue === 'other'

            if (!ownSensorGoalIsFreeText) {
              setGoalSurveyAnswer(humanizeOwnSensorGoalSurveyAnswer(surveyResponseValue))
              return
            }

            const freeTextSurveyResponse = surveyLink?.responses.find(
              (response) => response.questionKey === SurveysQuestionKey.OwnSensorGoalOther,
            )
            setGoalSurveyAnswer(freeTextSurveyResponse?.answer['value'])
          }
        }
      },
      complete: () => {
        setGoalSurveyLinkLoaded(true)
      },
    })
  }, [dispatch, navigation])

  if (!goalSurveyLinkLoaded) {
    return <LoadingContainer loadingMessage="Loading..." />
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'See If You Qualify', onPress: onSeeIfYouQualifyPress }}
      showDismissButton
      onDismiss={onDismiss}
      dismissButtonText="Check Later"
      onClose={onDismiss}
      buttonDirection="column-reverse"
    >
      <Image
        source={sensorActivatedNutritionGuidanceImage.imageSource}
        style={styles.image as ImageStyle}
      />
      {goalSurveyAnswer && (
        <Text style={styles.content} type="title-2">
          You mentioned you need help with {humanizeOwnSensorGoalSurveyAnswer(goalSurveyAnswer)}
        </Text>
      )}
      <Text style={styles.content} type="regular">
        See if you qualify for FREE personalized nutritionist coaching to help you achieve lasting
        results.
      </Text>
      <View style={styles.stepsContainer}>
        <View style={styles.stepContainer}>
          <View style={styles.stepOrdinal}>
            <Text type="regular">1</Text>
          </View>
          <Text type="regular" style={styles.stepDescription}>
            Check your eligibility instantly and book your first call.
          </Text>
        </View>
        <View style={styles.stepContainer}>
          <View style={styles.stepOrdinal}>
            <Text type="regular">2</Text>
          </View>
          <Text type="regular" style={styles.stepDescription}>
            We'll verify your coverage and let you know all the details before your session.
          </Text>
        </View>
      </View>
      <Text style={styles.content} type="regular" bold>
        95% pay $0 out-of-pocket.
      </Text>
      <InlineAlert
        category={UiInlineAlert.Neutral}
        message="If insurance doesn’t cover the call, you won’t be charged without consent"
      />
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 8,
  },
  stepsContainer: {
    gap: 16,
    marginVertical: 16,
  },
  stepContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  stepOrdinal: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'theme.surface.banner',
  },
  stepDescription: {
    width: '90%',
  },
  image: {
    aspectRatio: 3 / 2,
    width: '100%',
    height: undefined,
    borderRadius: 20,
    paddingHorizontal: 16,
  },
})
