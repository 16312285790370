import React from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollableAvoidKeyboard } from '@components'
import { SignUpForm } from '@screens/SignUp'
import { Text } from '@src/components/base'

export const CreateAccountStelo = () => {
  const styles = useStyleSheet(themedStyle)

  const insets = useSafeAreaInsets()

  return (
    <ScrollableAvoidKeyboard
      style={styles.container}
      contentContainerStyle={[
        styles.content,
        { paddingTop: insets.top + 24, paddingBottom: insets.bottom + 16 },
      ]}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <Text style={styles.title} type="title-2">
        Create your account
      </Text>
      <SignUpForm showPhoneNumberConsentCheckbox />
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  title: {
    marginBottom: 24,
  },
  content: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
})
