import React from 'react'
import { useSelector } from 'react-redux'
import { RouteProp, useRoute } from '@react-navigation/native'
import {
  appointmentKindsSelector,
  appointmentTypesSelector,
  userSelector,
} from '@src/selectors/app'
import { StyleService, useStyleSheet } from '@src/style/service'
import { RootStackParamList } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppointmentSchedulingScreen, CallSchedulingScreen } from '..'

const DEFAULT_TITLE = 'Video Call'

export const CallSchedulingContainer = () => {
  const styles = useStyleSheet(themedStyles)

  const route = useRoute<RouteProp<RootStackParamList, 'CallScheduling'>>()

  const {
    appointmentId: rescheduleAppointmentId,
    appointmentType,
    appointmentKind,
    allowBackNavigation,
  } = route.params || {}

  const user = useSelector(userSelector)

  const appointmentTypes = useSelector(appointmentTypesSelector)
  const appointmentKinds = useSelector(appointmentKindsSelector)

  const appointmentTypeConfig = appointmentTypes.find((config) => config.key === appointmentType)
  const appointmentKindConfig = appointmentKinds.find((config) => config.key === appointmentKind)

  const handleTitle = () => {
    if (rescheduleAppointmentId) {
      return 'Reschedule Appointment'
    }

    if (user?.migrationFromInsuranceEnabled) {
      return `Schedule ${appointmentKindConfig?.title || DEFAULT_TITLE}`
    }

    return `Schedule ${appointmentTypeConfig?.title || DEFAULT_TITLE}`
  }

  return (
    <NavigationContainer
      title={handleTitle()}
      allowBackNavigation={allowBackNavigation}
      navigationBarProps={{ titleStyle: styles.navigationTitle }}
    >
      {user?.migrationFromInsuranceEnabled ? (
        <AppointmentSchedulingScreen />
      ) : (
        <CallSchedulingScreen />
      )}
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  navigationTitle: {
    maxWidth: '90%',
  },
  content: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
  },
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  alert: {
    marginVertical: 8,
  },
  slotsList: {
    flex: 1,
    marginTop: 8,
  },
  slotItemContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 8,
  },
  slotButton: {
    flexGrow: 1,
  },
  loadingSlot: {
    height: 56,
    marginBottom: 8,
    borderRadius: 40,
  },
  backIcon: {
    marginLeft: 16,
  },
})
