import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { ReactNativeFile } from '@src/utils/image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { ProfilePhoto, ScrollableAvoidKeyboard } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { SignUpForm } from '@screens/SignUp'
import { Asset } from '@utils/image'

export const SignUpContainer = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  const [photo, setPhoto] = useState<Partial<Asset>>()
  const [photoSource, setPhotoSource] = useState<{ uri: string }>({ uri: '' })

  const onChangeProfilePhoto = (file: ReactNativeFile) => {
    setPhoto(file)
    setPhotoSource({ uri: file.uri })
  }

  const onSignInPress = () => navigation.navigate('SignIn')

  return (
    <NavigationContainer title="Create your account" style={styles.container}>
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={styles.content}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
      >
        <SafeAreaView edges={['bottom']} style={{ flex: 1 }}>
          <View style={styles.headerContainer}>
            <ProfilePhoto source={photoSource} onPhotoChange={onChangeProfilePhoto} />
            <Text type="regular" bold style={styles.photoLabel}>
              Add a photo
            </Text>
          </View>
          <SignUpForm photo={photo} showTermsOfUseConsentCheckbox />
          <View style={styles.bottom}>
            <Text type="regular" lineSpacing="none">
              Already have an account?
            </Text>
            <TouchableOpacity onPress={onSignInPress} accessibilityLabel="Sign in">
              <Text type="regular" lineSpacing="none" bold style={styles.signIn}>
                Sign In
              </Text>
            </TouchableOpacity>
          </View>
        </SafeAreaView>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  headerContainer: {
    paddingVertical: 24,
    alignItems: 'center',
  },
  photoLabel: {
    marginTop: 16,
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 16,
  },
  signIn: {
    marginLeft: 4,
    color: 'theme.text.link',
  },
})
