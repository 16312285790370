import React from 'react'
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack'
import {
  AdminModal,
  PasswordResetRequestScreen,
  PasswordResetRequestSuccessScreen,
  PasswordResetScreen,
  SignInOptionsScreen,
  SignInOrSignUpScreen,
  SignInScreen,
  SignUpScreen,
} from '@screens'
import { useReducedMotion } from '@src/config/accessibility'
import {
  CreateAccountScreen,
  CreateAccountOptionsScreen,
  CreateAccountOptionsSteloScreen,
  GettingStartedScreen,
  WalkthroughCarouselScreen,
  CreateAccountSteloScreen,
} from '@src/screens/SignUp'
import { Feature, useFeatureFlag } from '@src/components'
import { SensorSelectionScreen } from '@src/screens/OwnSensorOnboarding/SensorSelectionScreen'
import { fadeFromBottomModalOptions } from '../utils'
import { AuthStackParamList } from '../types'

const AuthStack = createStackNavigator<AuthStackParamList>()

export const AuthNavigator = () => {
  const reduceMotionEnabled = useReducedMotion()

  const screenOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  const onboardingFlowWithGoalQuestionEnabled = useFeatureFlag(
    Feature.OnboardingFlowWithGoalQuestion,
  )

  const getInitialRouteName = () => {
    if (onboardingFlowWithGoalQuestionEnabled) {
      return 'SignInOrSignUp' as const
    }

    return 'SignInOptions' as const
  }

  const initialRouteName = getInitialRouteName()

  return (
    <AuthStack.Navigator initialRouteName={initialRouteName} screenOptions={screenOptions}>
      {onboardingFlowWithGoalQuestionEnabled && (
        <>
          <AuthStack.Screen name="SignInOrSignUp" component={SignInOrSignUpScreen} />
          <AuthStack.Screen name="Walkthrough" component={WalkthroughCarouselScreen} />
        </>
      )}
      <AuthStack.Screen name="SignInOptions" component={SignInOptionsScreen} />
      <AuthStack.Screen name="SignIn" component={SignInScreen} />
      <AuthStack.Screen name="SignUp" component={SignUpScreen} />
      <AuthStack.Screen name="CreateAccount" component={CreateAccountScreen} />
      <AuthStack.Screen name="CreateAccountStelo" component={CreateAccountSteloScreen} />
      <AuthStack.Screen name="CreateAccountOptions" component={CreateAccountOptionsScreen} />
      <AuthStack.Screen
        name="CreateAccountOptionsStelo"
        component={CreateAccountOptionsSteloScreen}
      />
      <AuthStack.Screen name="GettingStarted" component={GettingStartedScreen} />
      <AuthStack.Screen name="PasswordResetRequest" component={PasswordResetRequestScreen} />
      <AuthStack.Screen name="PasswordReset" component={PasswordResetScreen} />
      <AuthStack.Screen name="OwnSensorSelection" component={SensorSelectionScreen} />
      <AuthStack.Screen
        name="PasswordResetRequestSuccess"
        component={PasswordResetRequestSuccessScreen}
      />
      <AuthStack.Screen
        name="ShowAdmin"
        component={AdminModal}
        options={fadeFromBottomModalOptions}
      />
    </AuthStack.Navigator>
  )
}
