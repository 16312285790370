import React from 'react'
import { FlatList, View } from 'react-native'
import { useSelector } from 'react-redux'
import { compact } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { dailyActionsWithCompletionsSelector } from '@src/selectors/dailyActions'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import {
  HomeScreenWalkthroughSteps,
  useHomeScreenWalkthrough,
} from '@src/context/homeScreenWalkthroughContext'

import { CustomEventTypes } from '@src/config/analytics'
import { Analytics } from '@src/config'
import { DailyAction, MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { InformationPopups } from '@src/components/InformationPopups'
import { NutritionSummaryCard } from '@src/components/NutritionSummaryCard/NutritionSummaryCard'
import { useGetByosVideoCallUpsellNextStep } from '../Home/utils/hooks'
import { ByosVideoCallsUpsellStep } from '../Home/constants'
import { NutritionGuidanceUpsellCard } from '../Home/components/Banners/NutritionGuidanceUpsellCard'
import { FreeConsultationUpsellCard } from '../Home/components/Banners/FreeConsultationUpsellCard'
import { CgmUpsellCard } from './CgmUpsellCard'
import { DailyActionCard } from './DailyActionCard'

export const CGM_UPSELL_CARD_ID = 'cgm-upsell-card'

export interface DailyActionsSectionProps {
  HeroChartComponent: React.ReactNode
}

type DailyActionsListItem = (DailyAction & { completed: boolean }) | { id: string }

export const DailyActionsSection = ({ HeroChartComponent }: DailyActionsSectionProps) => {
  const dailyActions = useSelector(dailyActionsWithCompletionsSelector)
  const showGlucoseChart = User.hasFeature(MobileAppFeature.ViewGlucoseChart)
  const styles = useStyleSheet(themedStyle)
  const nextByosVideoCallsUpsellStep = useGetByosVideoCallUpsellNextStep()

  const { shouldShow, advanceWalkthroughToNextStep, isStepVisible } = useHomeScreenWalkthrough()

  const listItems = compact([
    ...dailyActions,
    !showGlucoseChart ? { id: CGM_UPSELL_CARD_ID } : null,
  ])

  const renderItem = ({ item }: { item: DailyActionsListItem }) => {
    if (item.id === CGM_UPSELL_CARD_ID) {
      return <CgmUpsellCard />
    }
    return <DailyActionCard dailyAction={item as DailyAction & { completed: boolean }} />
  }

  // we need to render the FlatList with the Walkthrough component in order for the list to scroll
  // so based on shouldShow, we render the FlatList with or without the Walkthrough component
  const content = (
    <View style={styles.container}>
      <FlatList
        ListHeaderComponentStyle={styles.listHeaderStyles}
        ListHeaderComponent={
          <>
            {HeroChartComponent}
            <InformationPopups />
            {nextByosVideoCallsUpsellStep === ByosVideoCallsUpsellStep.ActionItem && (
              <NutritionGuidanceUpsellCard />
            )}
            {nextByosVideoCallsUpsellStep ===
              ByosVideoCallsUpsellStep.FreeConsultationActionItem && <FreeConsultationUpsellCard />}
            <View style={styles.listHeaderActions}>
              <Text type="large" bold style={styles.listHeaderTitle}>
                Today's Focus
              </Text>
            </View>
            {showGlucoseChart && (
              <View style={styles.nutritionSummaryContainer}>
                <NutritionSummaryCard />
              </View>
            )}
          </>
        }
        showsVerticalScrollIndicator={false}
        data={listItems}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        contentContainerStyle={styles.list}
      />
    </View>
  )

  const contentWithWalkthrough = (
    <>
      {HeroChartComponent}
      <InformationPopups />
      <WithWalkthrough
        isVisible={isStepVisible(HomeScreenWalkthroughSteps.DailyActions)}
        onClose={() => {
          Analytics.track(CustomEventTypes.HomeScreenWalkthroughDailyActionsSectionTapped)
          advanceWalkthroughToNextStep()
        }}
        allowChildInteraction={false}
        accessibilityLabel="Daily Actions Section"
        content={
          <WalkthroughTooltip
            title="Daily Actions to Complete"
            subtitle="Check off what’s important. Tap “Customize” to adjust."
          />
        }
        placement="top"
      >
        <View style={styles.walkthroughFocusContainer}>
          <FlatList
            ListHeaderComponentStyle={styles.listHeaderStyles}
            ListHeaderComponent={
              <>
                <View style={styles.listHeaderActions}>
                  <Text type="large" bold style={styles.listHeaderTitle}>
                    Today's Focus
                  </Text>
                </View>
                {showGlucoseChart && (
                  <View style={styles.nutritionSummaryContainer}>
                    <NutritionSummaryCard />
                  </View>
                )}
              </>
            }
            showsVerticalScrollIndicator={false}
            data={dailyActions.slice(0, 2)} // show only 2 highlighted actions in the walkthrough
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => <DailyActionCard dailyAction={item} />}
            contentContainerStyle={styles.walkthroughList}
          />
        </View>
      </WithWalkthrough>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={dailyActions.slice(2)} // show the rest of the actions in the list below the walkthrough
        keyExtractor={(item) => item.id}
        renderItem={({ item }) => <DailyActionCard dailyAction={item} />}
        contentContainerStyle={styles.list}
      />
    </>
  )

  return shouldShow ? contentWithWalkthrough : content
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  nutritionSummaryContainer: {
    marginHorizontal: 16,
    marginBottom: 16,
    marginRight: 8,
  },
  walkthroughFocusContainer: {
    borderRadius: 24,
    backgroundColor: 'theme.background',
  },
  listHeaderStyles: { marginHorizontal: -16 },
  listHeaderActions: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 4,
    marginTop: 16,
    marginHorizontal: 16,
  },
  listHeaderTitle: { alignSelf: 'center' },
  walkthroughList: {
    marginHorizontal: 16,
  },
  list: {
    marginHorizontal: 16,
    paddingBottom: 96,
  },
})
