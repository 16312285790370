import React, { useContext } from 'react'
import { IntroScreen } from '@src/types'
import { QuestionnaireContext } from '../../questionnaire.navigator'
import { SectionWithBackgroundImage } from './SectionWithBackgroundImage'
import { SectionWithoutBackgroundImage } from './SectionWithoutBackgroundImage'

export const QuestionnaireSectionStartScreen = () => {
  const {
    sectionStartScreenConfig: { introScreen },
  } = useContext(QuestionnaireContext)

  const { backgroundImageUrl } = introScreen as IntroScreen

  return backgroundImageUrl ? <SectionWithBackgroundImage /> : <SectionWithoutBackgroundImage />
}
