import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { View, TouchableWithoutFeedback, Alert } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Checkbox, Text } from '@components/base'
import { NutrisenseLogo } from '@components'
import { useSignIn } from '@src/hooks/useSignIn'
import { SignInButton, SignInButtonVariant } from '@screens/SignIn'
import {
  consumerHealthDataPrivacyPolicySelector,
  privacyPolicySelector,
  telehealthSelector,
  termsOfUseSelector,
} from '@selectors/app'
import { openUrl } from '@utils'
import { useSnack } from '@utils/navigatorContext'
import { useAllowSignInWithApple, useAllowSignInWithGoogle } from '@utils/hooks'

export const CreateAccountOptions = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const showSnack = useSnack()
  const termsOfUseUrl = useSelector(termsOfUseSelector)
  const privacyPolicyUrl = useSelector(privacyPolicySelector)
  const telehealthPolicyUrl = useSelector(telehealthSelector)
  const consumerHealthDataPrivacyPolicyUrl = useSelector(consumerHealthDataPrivacyPolicySelector)
  const allowSignInWithGoogle = useAllowSignInWithGoogle()
  const allowSignInWithApple = useAllowSignInWithApple()
  const { signUpWithApple, signUpWithGoogle } = useSignIn()
  const [eligibilityConfirmed, setEligibilityConfirmed] = useState(false)

  const displayEligibilityAlert = () => {
    Alert.alert(
      'Terms of Service and Eligibility Check',
      "I confirm that I have read, consent and agree to Nutrisense's Terms of Service and Eligibility Check.",
      [
        { text: 'Cancel' },
        { text: 'Agree', onPress: () => navigation.navigate('CreateAccount'), style: 'cancel' },
      ],
      { cancelable: true },
    )
  }

  const onSignUpWithEmailPress = () => {
    if (!eligibilityConfirmed) {
      displayEligibilityAlert()
      return
    }

    navigation.navigate('CreateAccount')
  }

  const onSignUpWithGooglePress = async () => {
    if (!eligibilityConfirmed) {
      displayEligibilityAlert()
      return
    }

    try {
      await signUpWithGoogle()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onSignUpWithApplePress = async () => {
    if (!eligibilityConfirmed) {
      displayEligibilityAlert()
      return
    }

    try {
      await signUpWithApple()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
      <View style={styles.headerContainer} testID="SigninScreen/Logo">
        <NutrisenseLogo style={{ marginBottom: 16 }} />
        <Text type="title-3">Create your account</Text>
      </View>
      <View style={styles.mainContent}>
        <SignInButton
          variant={SignInButtonVariant.Email}
          style={styles.signInButton}
          onPress={onSignUpWithEmailPress}
        />
        {allowSignInWithGoogle && (
          <SignInButton
            variant={SignInButtonVariant.Google}
            style={styles.signInButton}
            onPress={onSignUpWithGooglePress}
          />
        )}
        {allowSignInWithApple && (
          <SignInButton
            variant={SignInButtonVariant.Apple}
            style={styles.signInButton}
            onPress={onSignUpWithApplePress}
          />
        )}

        <View style={styles.eligibilityCheckContainer}>
          <Checkbox
            checked={eligibilityConfirmed}
            onChange={() => setEligibilityConfirmed(!eligibilityConfirmed)}
            text={
              <Text type="regular" style={styles.eligibilityCheckCheckboxContent}>
                I confirm that I am eligible for Nutrisense programs*
              </Text>
            }
          />
        </View>
      </View>
      <View style={styles.bottom}>
        <Text type="small" style={styles.eligibilityCheckText}>
          <Text type="small" bold>
            *Eligibility Check:
          </Text>{' '}
          People who are under 18, currently pregnant, actively using exogenous insulin, actively
          diagnosed with an eating disorder, or undergoing cancer treatment are not eligible.
        </Text>
        <Text type="regular" style={styles.termsOfService}>
          By creating an account you agree to our{' '}
          <TouchableWithoutFeedback
            accessibilityLabel="Terms of Use"
            onPress={() => openUrl(termsOfUseUrl)}
            testID="SignInOptionsScreen/TermsOfServiceButton"
          >
            <Text type="regular" bold>
              Terms & Conditions
            </Text>
          </TouchableWithoutFeedback>
          ,{' '}
          <TouchableWithoutFeedback
            accessibilityLabel="Telehealth Consent"
            onPress={() => openUrl(telehealthPolicyUrl)}
          >
            <Text type="regular" bold>
              Telehealth Consent
            </Text>
          </TouchableWithoutFeedback>
          ,{' '}
          <TouchableWithoutFeedback
            accessibilityLabel="Privacy Policy"
            onPress={() => openUrl(privacyPolicyUrl)}
          >
            <Text type="regular" bold>
              Privacy Policy
            </Text>
          </TouchableWithoutFeedback>
          ,{' '}
          <TouchableWithoutFeedback
            accessibilityLabel="Consumer Health Data Privacy Policy"
            onPress={() => openUrl(consumerHealthDataPrivacyPolicyUrl)}
          >
            <Text type="regular" bold>
              Consumer Health Data Privacy Policy
            </Text>
          </TouchableWithoutFeedback>
        </Text>
      </View>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  mainContent: {
    borderRadius: 24,
    backgroundColor: 'theme.surface.base2',
    padding: 16,
  },
  headerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  signInButton: {
    marginBottom: 16,
    backgroundColor: 'theme.background',
  },
  bottom: {
    flex: 1,
    justifyContent: 'space-between',
    marginTop: 24,
  },
  termsOfService: {
    marginTop: 16,
    textAlign: 'center',
  },
  eligibilityCheckText: {
    alignSelf: 'center',
    textAlign: 'center',
    maxWidth: 350,
  },
  eligibilityCheckContainer: {
    padding: 8,
    paddingLeft: 24,
    flexDirection: 'row',
  },
  eligibilityCheckCheckboxContent: {
    marginLeft: 16,
    maxWidth: 250,
  },
})
