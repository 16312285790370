import { Image, ImageResizeMode, ImageStyle, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { Icon, Text } from '@components/base'
import { StyleService, useStyleSheet } from '@style/service.ts'

interface ProductSummaryProps {
  title: string
  description: string
  image: { imageSource: any }
  imageResizeMode?: ImageResizeMode
  onPress: () => void
}

export const ProductSummary = ({
  title,
  description,
  image,
  imageResizeMode,
  onPress,
}: ProductSummaryProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <TouchableOpacity accessibilityLabel={title} onPress={onPress}>
      <View style={styles.card}>
        <View style={styles.row}>
          <View style={styles.rowText}>
            <Text type="title-3" style={styles.titleGap}>
              {title}
            </Text>
            <Text type="regular">{description}</Text>
          </View>

          <View style={styles.rowImage}>
            <Image
              style={styles.image as ImageStyle}
              resizeMode={imageResizeMode}
              source={image.imageSource}
            />
          </View>

          <Icon name="caret-right" style={styles.arrowIcon} />
        </View>
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  card: {
    flex: 1,
    flexDirection: 'row',
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.surface.base2',
    borderRadius: 24,
    borderWidth: 2,
    marginVertical: 8,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
  },
  rowText: {
    flex: 1.5,
    paddingTop: 8,
    paddingBottom: 24,
  },
  rowImage: {
    flex: 1,
  },
  titleGap: {
    marginBottom: 4,
  },
  arrowIcon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  image: {
    width: '100%',
    height: '100%',
  },
})
